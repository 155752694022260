* {
  font-family: 'Montserrat', sans-serif;
}


@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,600;0,800;0,900;1,500;1,600;1,700;1,900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Passion+One:wght@400;700;900&display=swap');

.title:hover {
  cursor: pointer;
}

@keyframes changeFontColor {
  0% {
    color: #f6002c;
    /* Initial color */
  }

  25% {
    color: #ff2c7d;
    /* Change color at 25% */
  }

  50% {
    color: #c900a9;
    /* Change color at 50% */
  }

  75% {
    color: #6f4bf6;
    /* Change color at 75% */
  }

  100% {
    color: #21afeb;
    /* Back to initial color */
  }
}

.nameClaim:hover {
  background-color: rgb(56, 116, 0);
}

::-webkit-scrollbar {
  width: 13px;
  display: none;
}

/* Track */
::-webkit-scrollbar-track {
  background: #3b3b3b;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #fb2ef9;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #b600b3;
}


@media screen and (max-width: 499px) {

  .connect2btns {
    display: flex;
  }

  .connect2-2-Mob-2 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    background-color: #3d3d3d67;
    width: 100px;
    border-radius: 50px;
    border: white 1px solid;
    margin-left: 20px;
    cursor: pointer;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-top: 2px;
  }

  .mm {
    width: 30px;
    height: 30px;
  }

  .connect2-2-Mob {
    width: 30px;
    height: 30px;
  }

  .connect2-2-Mob {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    background-color: #3d3d3d67;
    width: 100px;
    border-radius: 50px;
    border: white 1px solid;
    cursor: pointer;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .connect2-2-Mob2 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    background-color: #3d3d3d67;
    width: fit-content;
    border-radius: 50px;
    border: white 1px solid;
    cursor: pointer;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    padding-left: 20px;
    padding-right: 20px;
   }

  .wA {
    margin-top: 3px;
  }

  .miniBarMain {
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100vw;
    height: fit-content;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #232323;
    display: flex;
    justify-content: space-evenly;
  }

  .miniBar {
    display: flex;
    justify-content: space-between;
  }

  .navbarMainImgSet {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    align-items: center;
    display: flex;
  }

  .navbar {
    width: 40px;
    justify-content: flex-end;
    cursor: pointer;
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
    display: block;
    align-items: center;
  }

  .littleNav div {
    text-align: left;
    padding: 30px;
    cursor: pointer;
    border-top: #2ba531 1px solid;
  }

  .littleNav {
    background-color: #1a1a1ad8;
    width: 100%;
    height: max-content;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1000000000;
    flex-flow: column wrap;
    display: flex;
    border-bottom: 5px #196e1e solid;
  }

  .hidden {
    display: none;
  }

  .close {
    width: 35px;
    height: 35px;
    justify-content: flex-end;
    margin-left: auto;
    cursor: pointer;
    transition: transform .3s;
  }

  .navLink {
    color: #ff000091;
  }

  .connectMob {
    display: none;
  }

  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-top: 100px;
    padding-bottom: 100px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 51%, rgba(0, 0, 0, 1) 100%), url('./assets/footer.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .logoF img {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 50%;
    display: flex;
  }

  .footerImg {
    display: flex;
    justify-content: center;
  }

  .footerImg img {
    width: 20px;
    height: 20px;
    margin-left: 10px;
    margin-right: 10px;
    transition: transform .2s;
    margin-bottom: 10px;
    margin-top: 5px;
  }

  .footerImg img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  .footer2 {
    display: flex;
    justify-content: center;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    flex-flow: column nowrap;
  }

  .copyright {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 13px;
  }

  .footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: rgba(102, 102, 102, 0);
    width: 100%;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #020529b7;
    display: none;
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    width: 95%;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    height: 5vh;
  }

  .icons {
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-left: 10px;
    width: 33.33%;
  }

  .icons img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    transition: transform .2s;
    z-index: 10000000;
  }

  .icons img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  .button-87 {
    margin: 10px;
    padding: 10px 20px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    border-radius: 10px;
    display: block;
    border: 0px;
    font-weight: 700;
    box-shadow: 0px 0px 14px -7px #f09819;
    background-image: linear-gradient(45deg, #FF512F 0%, #F09819 51%, #FF512F 100%);
    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    font-size: 16px;
    display: none;
  }

  .button-87:hover {
    background-position: right center;
    /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
  }

  .button-87:active {
    transform: scale(0.95);
  }

  .title {
    color: white;
    font-family: 'Passion One', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: center;
    font-size: 25px;
    animation: changeFontColor 5s infinite alternate;
  }

  .logoUp {
    width: 40px;
    height: 40px;
    margin-top: -5px;
    margin-right: 10px;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;
  }

  /* CSS */
  .button-62 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    background-color: #bb5a3c;
    font-size: 18px;
    padding: 13px;
    width: 200px;
    color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    transition: 0.3s ease;
    border-radius: 7px;
    border: none;
    font-weight: 500;
  }

  .button-62:hover {
    color: rgb(219, 219, 219);
  }

  .logo2 img {
    width: 75px;
    height: 75px;
  }

}

@media screen and (min-width: 500px) and (max-width: 767px) {

  .connect2btns {
    display: flex;
  }

  .connect2-2-Mob-2 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    background-color: #3d3d3d67;
    width: 100px;
    border-radius: 50px;
    border: white 1px solid;
    margin-left: 20px;
    cursor: pointer;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-top: 2px;
  }

  .mm {
    width: 30px;
    height: 30px;
  }

  .connect2-2-Mob {
    width: 30px;
    height: 30px;
  }

  .connect2-2-Mob {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    background-color: #3d3d3d67;
    width: 100px;
    border-radius: 50px;
    border: white 1px solid;
    cursor: pointer;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;

  }

  .connect2-2-Mob2 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    background-color: #3d3d3d67;
    width: 150px;
    border-radius: 50px;
    border: white 1px solid;
    cursor: pointer;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;

  }

  .wA {
    margin-top: 3px;
  }

  .miniBarMain {
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100vw;
    height: fit-content;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #232323;
    display: flex;
    justify-content: space-between;
  }

  .miniBar {
    display: flex;
    justify-content: space-between;
  }

  .navbarMainImgSet {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    align-items: center;
    display: flex;
  }

  .navbar {
    width: 40px;
    justify-content: flex-end;
    cursor: pointer;
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
    display: block;
    align-items: center;
  }

  .littleNav div {
    text-align: left;
    padding: 30px;
    cursor: pointer;
    border-top: #2ba531 1px solid;
  }

  .littleNav {
    background-color: #1a1a1ad8;
    width: 100%;
    height: max-content;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1000000000;
    flex-flow: column wrap;
    display: flex;
    border-bottom: 5px #196e1e solid;
  }

  .hidden {
    display: none;
  }

  .close {
    width: 35px;
    height: 35px;
    justify-content: flex-end;
    margin-left: auto;
    cursor: pointer;
    transition: transform .3s;
  }

  .navLink {
    color: #ff000091;
  }

  .connectMob {
    display: none;
  }

  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-top: 100px;
    padding-bottom: 100px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 51%, rgba(0, 0, 0, 1) 100%), url('./assets/footer.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .logoF img {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 50%;
    display: flex;
  }

  .footerImg {
    display: flex;
    justify-content: center;
  }

  .footerImg img {
    width: 20px;
    height: 20px;
    margin-left: 10px;
    margin-right: 10px;
    transition: transform .2s;
    margin-bottom: 10px;
    margin-top: 5px;
  }

  .footerImg img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  .footer2 {
    display: flex;
    justify-content: center;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    flex-flow: column nowrap;
  }

  .copyright {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 13px;
  }

  .footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: rgba(102, 102, 102, 0);
    width: 100%;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #020529b7;
    display: none;
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    width: 95%;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    height: 5vh;
  }

  .icons {
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-left: 10px;
    width: 33.33%;
  }

  .icons img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    transition: transform .2s;
    z-index: 10000000;
  }

  .icons img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  .button-87 {
    margin: 10px;
    padding: 10px 20px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    border-radius: 10px;
    display: block;
    border: 0px;
    font-weight: 700;
    box-shadow: 0px 0px 14px -7px #f09819;
    background-image: linear-gradient(45deg, #FF512F 0%, #F09819 51%, #FF512F 100%);
    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    font-size: 16px;
    display: none;
  }

  .button-87:hover {
    background-position: right center;
    /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
  }

  .button-87:active {
    transform: scale(0.95);
  }

  .title {
    color: white;
    font-family: 'Passion One', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: center;
    font-size: 25px;
    animation: changeFontColor 5s infinite alternate;
  }

  .logoUp {
    width: 40px;
    height: 40px;
    margin-top: -5px;
    margin-right: 10px;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;
  }

  /* CSS */
  .button-62 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    background-color: #bb5a3c;
    font-size: 18px;
    padding: 13px;
    width: 200px;
    color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    transition: 0.3s ease;
    border-radius: 7px;
    border: none;
    font-weight: 500;
  }

  .button-62:hover {
    color: rgb(219, 219, 219);
  }

  .logo2 img {
    width: 75px;
    height: 75px;
  }

}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .connect2-2-Mob {
    display: none;
  }

  .connectMob {
    display: none;
  }

  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-top: 100px;
    padding-bottom: 100px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 51%, rgba(0, 0, 0, 1) 100%), url('./assets/footer.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .littleNav {
    display: none;
  }

  .miniBarMain {
    display: none;
  }

  .logosMob {
    display: none;
  }

  .logos {
    width: 30%;
    height: 30%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
  }

  .logos_mobile {
    width: 30%;
    height: 30%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
    display: none;
  }

  .logoF img {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 30%;
    display: flex;
  }

  .footer2 {
    display: flex;
    justify-content: center;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    flex-flow: column nowrap;
  }

  .footerImg {

    display: flex;
    justify-content: center;
  }

  .footerImg img {
    width: 20px;
    height: 20px;
    margin-left: 10px;
    margin-right: 10px;
    transition: transform .2s;
    margin-bottom: 10px;
    margin-top: 5px;
  }

  .footerImg img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  .footer2 {
    display: flex;
    justify-content: center;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    flex-flow: column nowrap;
  }

  .copyright {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 13px;
  }

  .footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: rgba(102, 102, 102, 0);
    width: 100%;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #232323;

  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    width: 95%;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .icons {
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-left: 10px;
  }

  .icons img {
    width: 30px;
    height: 30px;
    margin-left: 10px;
    margin-right: 10px;
    transition: transform .2s;
    z-index: 10000000;
  }

  .icons img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  /* CSS */
  .button-87 {
    margin: 10px;
    padding: 15px 30px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    border-radius: 10px;
    display: block;
    border: 0px;
    font-weight: 700;
    box-shadow: 0px 0px 14px -7px #f09819;
    background-image: linear-gradient(45deg, #FF512F 0%, #F09819 51%, #FF512F 100%);
    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    font-size: 18px;
  }

  .button-87:hover {
    background-position: right center;
    /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
  }

  .button-87:active {
    transform: scale(0.95);
  }

  .title {
    color: white;
    font-family: 'Passion One', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: center;
    font-size: 35px;
    animation: changeFontColor 5s infinite alternate;
    /* Change 5s to your desired duration */
  }


  .logoUp {
    width: 50px;
    height: 50px;
    margin-top: -8px;
    margin-right: 20px;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;
  }

  .logo2 img {
    width: 75px;
    height: 75px;
  }

  .downImg {
    width: 35px;
    animation: arrow_down_animation 2s ease-in-out infinite;
    cursor: pointer;
  }

}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .connect2-2-Mob {
    display: none;
  }

  .connectMob {
    display: none;
  }

  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-top: 100px;
    padding-bottom: 100px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 51%, rgba(0, 0, 0, 1) 100%), url('./assets/footer.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .littleNav {
    display: none;
  }

  .miniBarMain {
    display: none;
  }

  .logosMob {
    display: none;
  }

  .logos {
    width: 30%;
    height: 30%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
  }

  .logos_mobile {
    width: 30%;
    height: 30%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
    display: none;
  }

  .logoF img {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 20%;
    display: flex;
  }

  .footer2 {
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }


  .footerImg {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: space-evenly;
  }

  .footerImg img {
    margin-left: 5px;
    margin-right: 5px;
    transition: transform .2s;
  }

  .footerImg img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  .footer2 {
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .copyright {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 13px;
  }


  .footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: rgba(102, 102, 102, 0);
    width: 100%;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #232323;

  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    width: 95%;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .icons {
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-left: 10px;
  }

  .icons img {
    width: 30px;
    height: 30px;
    margin-left: 10px;
    margin-right: 10px;
    transition: transform .2s;
    z-index: 10000000;
  }

  .icons img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  /* CSS */
  .button-87 {
    margin: 10px;
    padding: 15px 30px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    border-radius: 10px;
    display: block;
    border: 0px;
    font-weight: 700;
    box-shadow: 0px 0px 14px -7px #f09819;
    background-image: linear-gradient(45deg, #FF512F 0%, #F09819 51%, #FF512F 100%);
    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    font-size: 18px;
  }

  .button-87:hover {
    background-position: right center;
    /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
  }

  .button-87:active {
    transform: scale(0.95);
  }

  .title {
    color: white;
    font-family: 'Passion One', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: center;
    font-size: 35px;
    animation: changeFontColor 5s infinite alternate;
    /* Change 5s to your desired duration */
  }


  .logoUp {
    width: 50px;
    height: 50px;
    margin-top: -8px;
    margin-right: 20px;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;
  }

  .logo2 img {
    width: 75px;
    height: 75px;
  }

  .downImg {
    width: 35px;
    animation: arrow_down_animation 2s ease-in-out infinite;
    cursor: pointer;
  }

}

@media screen and (min-width: 1200px) and (max-width: 1919px) {
  .connect2-2-Mob {
    display: none;
  }

  .connectMob {
    display: none;
  }

  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-top: 100px;
    padding-bottom: 100px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 51%, rgba(0, 0, 0, 1) 100%), url('./assets/footer.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .littleNav {
    display: none;
  }

  .miniBarMain {
    display: none;
  }

  .logosMob {
    display: none;
  }

  .logos {
    width: 30%;
    height: 30%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
  }

  .logos_mobile {
    width: 30%;
    height: 30%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
    display: none;
  }

  .logoF img {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 20%;
    display: flex;
  }

  .footer2 {
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }


  .footerImg {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: space-evenly;
  }

  .footerImg img {
    margin-left: 5px;
    margin-right: 5px;
    transition: transform .2s;
  }

  .footerImg img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  .footer2 {
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .copyright {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 13px;
  }


  .footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: rgba(102, 102, 102, 0);
    width: 100%;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #232323;

  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    width: 95%;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .icons {
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-left: 10px;
  }

  .icons img {
    width: 30px;
    height: 30px;
    margin-left: 10px;
    margin-right: 10px;
    transition: transform .2s;
    z-index: 10000000;
  }

  .icons img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  /* CSS */
  .button-87 {
    margin: 10px;
    padding: 15px 30px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    border-radius: 10px;
    display: block;
    border: 0px;
    font-weight: 700;
    box-shadow: 0px 0px 14px -7px #f09819;
    background-image: linear-gradient(45deg, #FF512F 0%, #F09819 51%, #FF512F 100%);
    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    font-size: 18px;
  }

  .button-87:hover {
    background-position: right center;
    /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
  }

  .button-87:active {
    transform: scale(0.95);
  }

  .title {
    color: white;
    font-family: 'Passion One', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: center;
    font-size: 35px;
    animation: changeFontColor 5s infinite alternate;
    /* Change 5s to your desired duration */
  }


  .logoUp {
    width: 50px;
    height: 50px;
    margin-top: -8px;
    margin-right: 20px;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;
  }

  .logo2 img {
    width: 75px;
    height: 75px;
  }

  .downImg {
    width: 35px;
    animation: arrow_down_animation 2s ease-in-out infinite;
    cursor: pointer;
  }

}

@media screen and (min-width: 1920px) {
  .connect2-2-Mob {
    display: none;
  }

  .connectMob {
    display: none;
  }

  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-top: 150px;
    padding-bottom: 150px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 51%, rgba(0, 0, 0, 1) 100%), url('./assets/footer.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .littleNav {
    display: none;
  }

  .miniBarMain {
    display: none;
  }

  .logosMob {
    display: none;
  }

  .logos {
    width: 30%;
    height: 30%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
  }

  .logos_mobile {
    width: 30%;
    height: 30%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
    display: none;
  }

  .logoF img {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 20%;
    display: flex;
  }

  .footer2 {
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }


  .footerImg {
    width: 28px;
    height: 28px;
    display: flex;
    justify-content: space-evenly;
  }

  .footerImg img {
    margin-left: 5px;
    margin-right: 5px;
    transition: transform .2s;
  }

  .footerImg img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  .footer2 {
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .copyright {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 20px;
  }

  .footer2 {
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }


  .footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: rgba(102, 102, 102, 0);
    width: 100%;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #232323;

  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    width: 95%;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .icons {
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-left: 10px;
  }

  .icons img {
    width: 45px;
    height: 45px;
    margin-left: 15px;
    margin-right: 15px;
    transition: transform .2s;
    z-index: 10000000;
  }

  .icons img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  .button-87 {
    margin: 10px;
    padding: 20px 40px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    border-radius: 10px;
    display: block;
    border: 0px;
    font-weight: 700;
    box-shadow: 0px 0px 14px -7px #f09819;
    background-image: linear-gradient(45deg, #FF512F 0%, #F09819 51%, #FF512F 100%);
    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    font-size: 28px;
  }

  .button-87:hover {
    background-position: right center;
    /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
  }

  .button-87:active {
    transform: scale(0.95);
  }

  .title {
    color: white;
    font-family: 'Passion One', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: center;
    font-size: 50px;
    animation: changeFontColor 5s infinite alternate;
    /* Change 5s to your desired duration */
  }

  .logoUp {
    width: 80px;
    height: 80px;
    margin-top: -10px;
    margin-right: 30px;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;
  }

  .logo2 img {
    width: 75px;
    height: 75px;
  }

  .downImg {
    width: 35px;
    animation: arrow_down_animation 2s ease-in-out infinite;
    cursor: pointer;
  }
}

@media screen and (min-width: 1921px) and (max-width: 3839px) {
  .connect2-2-Mob {
    display: none;
  }

  .connectMob {
    display: none;
  }

  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-top: 200px;
    padding-bottom: 200px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 51%, rgba(0, 0, 0, 1) 100%), url('./assets/footer.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .littleNav {
    display: none;
  }

  .miniBarMain {
    display: none;
  }

  .logosMob {
    display: none;
  }

  .logos {
    width: 30%;
    height: 30%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
  }

  .logos_mobile {
    width: 30%;
    height: 30%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
    display: none;
  }

  .logoF img {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 20%;
    display: flex;
  }

  .footer2 {
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }


  .footerImg {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: space-evenly;
  }

  .footerImg img {
    margin-left: 10px;
    margin-right: 10px;
    transition: transform .2s;
  }

  .footerImg img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  .footer2 {
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .copyright {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 27px;
  }

  .footer2 {
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }


  .footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: rgba(102, 102, 102, 0);
    width: 100%;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    width: 100%;
    padding-bottom: 30px;
    padding-top: 30px;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #232323;
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    width: 95%;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
  }

  .connect div {
    margin-left: 40px;
    margin-right: 40px;
    color: white;
    margin-top: auto;
    justify-content: space-evenly;
    display: flex;
    cursor: pointer;
    transition: transform .2s;

  }

  .connect div:hover {
    transform: scale(1.3);
  }

  .icons {
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-left: 10px;
  }

  .icons img {
    width: 55px;
    height: 55px;
    margin-left: 20px;
    margin-right: 20px;
    transition: transform .2s;
    z-index: 10000000;
  }

  .icons img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  .button-87 {
    margin: 10px;
    padding: 25px 40px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    border-radius: 20px;
    display: block;
    border: 0px;
    font-weight: 700;
    box-shadow: 0px 0px 14px -7px #f09819;
    background-image: linear-gradient(45deg, #FF512F 0%, #F09819 51%, #FF512F 100%);
    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    font-size: 37px;
  }

  .button-87:hover {
    background-position: right center;
    /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
  }

  .button-87:active {
    transform: scale(0.95);
  }

  .title {
    color: white;
    font-family: 'Passion One', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: center;
    font-size: 70px;
    animation: changeFontColor 5s infinite alternate;
    /* Change 5s to your desired duration */
  }

  .logoUp {
    width: 125px;
    height: 125px;
    margin-top: -30px;
    margin-right: 50px;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;
  }

  .logo2 img {
    width: 75px;
    height: 75px;
  }

  .downImg {
    width: 35px;
    animation: arrow_down_animation 2s ease-in-out infinite;
    cursor: pointer;
  }
}

@media screen and (min-width: 3840px) {
  .connect2-2-Mob {
    display: none;
  }

  .connectMob {
    display: none;
  }

  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-top: 300px;
    padding-bottom: 300px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 51%, rgba(0, 0, 0, 1) 100%), url('./assets/footer.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .littleNav {
    display: none;
  }

  .miniBarMain {
    display: none;
  }

  .logosMob {
    display: none;
  }

  .logos {
    width: 30%;
    height: 30%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
  }

  .logos_mobile {
    width: 30%;
    height: 30%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
    display: none;
  }

  .logoF img {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 20%;
    display: flex;
  }

  .footer2 {
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .footerImg {
    width: 55px;
    height: 55px;
    display: flex;
    justify-content: space-evenly;
  }

  .footerImg img {
    margin-left: 15px;
    margin-right: 15px;
    transition: transform .2s;
  }

  .footerImg img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  .footer2 {
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .copyright {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 40px;
  }

  .footer2 {
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }


  .footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: rgba(102, 102, 102, 0);
    width: 100%;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-bottom: 40px;
    padding-top: 40px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #232323;

  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    width: 95%;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
  }

  .connect div {
    margin-left: 55px;
    margin-right: 55px;
    color: white;
    margin-top: auto;
    justify-content: space-evenly;
    display: flex;
    cursor: pointer;
    transition: transform .2s;


  }

  .connect div:hover {
    transform: scale(1.3);
  }

  .icons {
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-left: 10px;
  }

  .icons img {
    width: 80px;
    height: 80px;
    margin-left: 30px;
    margin-right: 30px;
    transition: transform .2s;
    z-index: 10000000;
  }

  .icons img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  .icons img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  .button-87 {
    margin: 10px;
    padding: 40px 50px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    border-radius: 30px;
    display: block;
    border: 0px;
    font-weight: 700;
    box-shadow: 0px 0px 14px -7px #f09819;
    background-image: linear-gradient(45deg, #FF512F 0%, #F09819 51%, #FF512F 100%);
    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    font-size: 55px;
  }

  .button-87:hover {
    background-position: right center;
    /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
  }

  .button-87:active {
    transform: scale(0.95);
  }

  .title {
    color: white;
    font-family: 'Passion One', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: center;
    font-size: 95px;
    animation: changeFontColor 5s infinite alternate;
    /* Change 5s to your desired duration */
  }

  .logoUp {
    width: 180px;
    height: 180px;
    margin-top: -45px;
    margin-right: 65px;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;
  }

  .logo2 img {
    width: 75px;
    height: 75px;
  }

  .downImg {
    width: 35px;
    animation: arrow_down_animation 2s ease-in-out infinite;
    cursor: pointer;
  }
}