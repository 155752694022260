@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,600;0,800;0,900;1,500;1,600;1,700;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Passion+One:wght@400;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Russo+One&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  font-family: 'Montserrat', sans-serif;
}

.title:hover {
  cursor: pointer;
}

@-webkit-keyframes changeFontColor {
  0% {
    color: #f6002c;
    /* Initial color */
  }

  25% {
    color: #ff2c7d;
    /* Change color at 25% */
  }

  50% {
    color: #c900a9;
    /* Change color at 50% */
  }

  75% {
    color: #6f4bf6;
    /* Change color at 75% */
  }

  100% {
    color: #21afeb;
    /* Back to initial color */
  }
}

@keyframes changeFontColor {
  0% {
    color: #f6002c;
    /* Initial color */
  }

  25% {
    color: #ff2c7d;
    /* Change color at 25% */
  }

  50% {
    color: #c900a9;
    /* Change color at 50% */
  }

  75% {
    color: #6f4bf6;
    /* Change color at 75% */
  }

  100% {
    color: #21afeb;
    /* Back to initial color */
  }
}

.nameClaim:hover {
  background-color: rgb(56, 116, 0);
}

::-webkit-scrollbar {
  width: 13px;
  display: none;
}

/* Track */
::-webkit-scrollbar-track {
  background: #3b3b3b;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #fb2ef9;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #b600b3;
}


@media screen and (max-width: 499px) {

  .connect2btns {
    display: flex;
  }

  .connect2-2-Mob-2 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    background-color: #3d3d3d67;
    width: 100px;
    border-radius: 50px;
    border: white 1px solid;
    margin-left: 20px;
    cursor: pointer;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-top: 2px;
  }

  .mm {
    width: 30px;
    height: 30px;
  }

  .connect2-2-Mob {
    width: 30px;
    height: 30px;
  }

  .connect2-2-Mob {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    background-color: #3d3d3d67;
    width: 100px;
    border-radius: 50px;
    border: white 1px solid;
    cursor: pointer;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .connect2-2-Mob2 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    background-color: #3d3d3d67;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border-radius: 50px;
    border: white 1px solid;
    cursor: pointer;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    padding-left: 20px;
    padding-right: 20px;
   }

  .wA {
    margin-top: 3px;
  }

  .miniBarMain {
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100vw;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #232323;
    display: flex;
    justify-content: space-evenly;
  }

  .miniBar {
    display: flex;
    justify-content: space-between;
  }

  .navbarMainImgSet {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    align-items: center;
    display: flex;
  }

  .navbar {
    width: 40px;
    justify-content: flex-end;
    cursor: pointer;
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
    display: block;
    align-items: center;
  }

  .littleNav div {
    text-align: left;
    padding: 30px;
    cursor: pointer;
    border-top: #2ba531 1px solid;
  }

  .littleNav {
    background-color: #1a1a1ad8;
    width: 100%;
    height: -webkit-max-content;
    height: max-content;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1000000000;
    flex-flow: column wrap;
    display: flex;
    border-bottom: 5px #196e1e solid;
  }

  .hidden {
    display: none;
  }

  .close {
    width: 35px;
    height: 35px;
    justify-content: flex-end;
    margin-left: auto;
    cursor: pointer;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
  }

  .navLink {
    color: #ff000091;
  }

  .connectMob {
    display: none;
  }

  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-top: 100px;
    padding-bottom: 100px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 51%, rgba(0, 0, 0, 1) 100%), url(/static/media/footer.c0569fc8.jpg) no-repeat center center fixed;
    background-size: cover;
  }

  .logoF img {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 50%;
    display: flex;
  }

  .footerImg {
    display: flex;
    justify-content: center;
  }

  .footerImg img {
    width: 20px;
    height: 20px;
    margin-left: 10px;
    margin-right: 10px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    margin-bottom: 10px;
    margin-top: 5px;
  }

  .footerImg img:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    cursor: pointer;
  }

  .footer2 {
    display: flex;
    justify-content: center;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    flex-flow: column nowrap;
  }

  .copyright {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 13px;
  }

  .footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: rgba(102, 102, 102, 0);
    width: 100%;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #020529b7;
    display: none;
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    width: 95%;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    height: 5vh;
  }

  .icons {
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-left: 10px;
    width: 33.33%;
  }

  .icons img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    z-index: 10000000;
  }

  .icons img:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    cursor: pointer;
  }

  .button-87 {
    margin: 10px;
    padding: 10px 20px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    border-radius: 10px;
    display: block;
    border: 0px;
    font-weight: 700;
    box-shadow: 0px 0px 14px -7px #f09819;
    background-image: linear-gradient(45deg, #FF512F 0%, #F09819 51%, #FF512F 100%);
    cursor: pointer;
    -ms-user-select: none;
        user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    font-size: 16px;
    display: none;
  }

  .button-87:hover {
    background-position: right center;
    /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
  }

  .button-87:active {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
  }

  .title {
    color: white;
    font-family: 'Passion One', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: center;
    font-size: 25px;
    -webkit-animation: changeFontColor 5s infinite alternate;
            animation: changeFontColor 5s infinite alternate;
  }

  .logoUp {
    width: 40px;
    height: 40px;
    margin-top: -5px;
    margin-right: 10px;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;
  }

  /* CSS */
  .button-62 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    background-color: #bb5a3c;
    font-size: 18px;
    padding: 13px;
    width: 200px;
    color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    transition: 0.3s ease;
    border-radius: 7px;
    border: none;
    font-weight: 500;
  }

  .button-62:hover {
    color: rgb(219, 219, 219);
  }

  .logo2 img {
    width: 75px;
    height: 75px;
  }

}

@media screen and (min-width: 500px) and (max-width: 767px) {

  .connect2btns {
    display: flex;
  }

  .connect2-2-Mob-2 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    background-color: #3d3d3d67;
    width: 100px;
    border-radius: 50px;
    border: white 1px solid;
    margin-left: 20px;
    cursor: pointer;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-top: 2px;
  }

  .mm {
    width: 30px;
    height: 30px;
  }

  .connect2-2-Mob {
    width: 30px;
    height: 30px;
  }

  .connect2-2-Mob {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    background-color: #3d3d3d67;
    width: 100px;
    border-radius: 50px;
    border: white 1px solid;
    cursor: pointer;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;

  }

  .connect2-2-Mob2 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    background-color: #3d3d3d67;
    width: 150px;
    border-radius: 50px;
    border: white 1px solid;
    cursor: pointer;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;

  }

  .wA {
    margin-top: 3px;
  }

  .miniBarMain {
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100vw;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #232323;
    display: flex;
    justify-content: space-between;
  }

  .miniBar {
    display: flex;
    justify-content: space-between;
  }

  .navbarMainImgSet {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    align-items: center;
    display: flex;
  }

  .navbar {
    width: 40px;
    justify-content: flex-end;
    cursor: pointer;
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
    display: block;
    align-items: center;
  }

  .littleNav div {
    text-align: left;
    padding: 30px;
    cursor: pointer;
    border-top: #2ba531 1px solid;
  }

  .littleNav {
    background-color: #1a1a1ad8;
    width: 100%;
    height: -webkit-max-content;
    height: max-content;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1000000000;
    flex-flow: column wrap;
    display: flex;
    border-bottom: 5px #196e1e solid;
  }

  .hidden {
    display: none;
  }

  .close {
    width: 35px;
    height: 35px;
    justify-content: flex-end;
    margin-left: auto;
    cursor: pointer;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
  }

  .navLink {
    color: #ff000091;
  }

  .connectMob {
    display: none;
  }

  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-top: 100px;
    padding-bottom: 100px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 51%, rgba(0, 0, 0, 1) 100%), url(/static/media/footer.c0569fc8.jpg) no-repeat center center fixed;
    background-size: cover;
  }

  .logoF img {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 50%;
    display: flex;
  }

  .footerImg {
    display: flex;
    justify-content: center;
  }

  .footerImg img {
    width: 20px;
    height: 20px;
    margin-left: 10px;
    margin-right: 10px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    margin-bottom: 10px;
    margin-top: 5px;
  }

  .footerImg img:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    cursor: pointer;
  }

  .footer2 {
    display: flex;
    justify-content: center;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    flex-flow: column nowrap;
  }

  .copyright {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 13px;
  }

  .footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: rgba(102, 102, 102, 0);
    width: 100%;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #020529b7;
    display: none;
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    width: 95%;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    height: 5vh;
  }

  .icons {
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-left: 10px;
    width: 33.33%;
  }

  .icons img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    z-index: 10000000;
  }

  .icons img:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    cursor: pointer;
  }

  .button-87 {
    margin: 10px;
    padding: 10px 20px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    border-radius: 10px;
    display: block;
    border: 0px;
    font-weight: 700;
    box-shadow: 0px 0px 14px -7px #f09819;
    background-image: linear-gradient(45deg, #FF512F 0%, #F09819 51%, #FF512F 100%);
    cursor: pointer;
    -ms-user-select: none;
        user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    font-size: 16px;
    display: none;
  }

  .button-87:hover {
    background-position: right center;
    /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
  }

  .button-87:active {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
  }

  .title {
    color: white;
    font-family: 'Passion One', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: center;
    font-size: 25px;
    -webkit-animation: changeFontColor 5s infinite alternate;
            animation: changeFontColor 5s infinite alternate;
  }

  .logoUp {
    width: 40px;
    height: 40px;
    margin-top: -5px;
    margin-right: 10px;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;
  }

  /* CSS */
  .button-62 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    background-color: #bb5a3c;
    font-size: 18px;
    padding: 13px;
    width: 200px;
    color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    transition: 0.3s ease;
    border-radius: 7px;
    border: none;
    font-weight: 500;
  }

  .button-62:hover {
    color: rgb(219, 219, 219);
  }

  .logo2 img {
    width: 75px;
    height: 75px;
  }

}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .connect2-2-Mob {
    display: none;
  }

  .connectMob {
    display: none;
  }

  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-top: 100px;
    padding-bottom: 100px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 51%, rgba(0, 0, 0, 1) 100%), url(/static/media/footer.c0569fc8.jpg) no-repeat center center fixed;
    background-size: cover;
  }

  .littleNav {
    display: none;
  }

  .miniBarMain {
    display: none;
  }

  .logosMob {
    display: none;
  }

  .logos {
    width: 30%;
    height: 30%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
  }

  .logos_mobile {
    width: 30%;
    height: 30%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
    display: none;
  }

  .logoF img {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 30%;
    display: flex;
  }

  .footer2 {
    display: flex;
    justify-content: center;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    flex-flow: column nowrap;
  }

  .footerImg {

    display: flex;
    justify-content: center;
  }

  .footerImg img {
    width: 20px;
    height: 20px;
    margin-left: 10px;
    margin-right: 10px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    margin-bottom: 10px;
    margin-top: 5px;
  }

  .footerImg img:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    cursor: pointer;
  }

  .footer2 {
    display: flex;
    justify-content: center;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    flex-flow: column nowrap;
  }

  .copyright {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 13px;
  }

  .footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: rgba(102, 102, 102, 0);
    width: 100%;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #232323;

  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    width: 95%;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .icons {
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-left: 10px;
  }

  .icons img {
    width: 30px;
    height: 30px;
    margin-left: 10px;
    margin-right: 10px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    z-index: 10000000;
  }

  .icons img:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    cursor: pointer;
  }

  /* CSS */
  .button-87 {
    margin: 10px;
    padding: 15px 30px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    border-radius: 10px;
    display: block;
    border: 0px;
    font-weight: 700;
    box-shadow: 0px 0px 14px -7px #f09819;
    background-image: linear-gradient(45deg, #FF512F 0%, #F09819 51%, #FF512F 100%);
    cursor: pointer;
    -ms-user-select: none;
        user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    font-size: 18px;
  }

  .button-87:hover {
    background-position: right center;
    /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
  }

  .button-87:active {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
  }

  .title {
    color: white;
    font-family: 'Passion One', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: center;
    font-size: 35px;
    -webkit-animation: changeFontColor 5s infinite alternate;
            animation: changeFontColor 5s infinite alternate;
    /* Change 5s to your desired duration */
  }


  .logoUp {
    width: 50px;
    height: 50px;
    margin-top: -8px;
    margin-right: 20px;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;
  }

  .logo2 img {
    width: 75px;
    height: 75px;
  }

  .downImg {
    width: 35px;
    -webkit-animation: arrow_down_animation 2s ease-in-out infinite;
            animation: arrow_down_animation 2s ease-in-out infinite;
    cursor: pointer;
  }

}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .connect2-2-Mob {
    display: none;
  }

  .connectMob {
    display: none;
  }

  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-top: 100px;
    padding-bottom: 100px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 51%, rgba(0, 0, 0, 1) 100%), url(/static/media/footer.c0569fc8.jpg) no-repeat center center fixed;
    background-size: cover;
  }

  .littleNav {
    display: none;
  }

  .miniBarMain {
    display: none;
  }

  .logosMob {
    display: none;
  }

  .logos {
    width: 30%;
    height: 30%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
  }

  .logos_mobile {
    width: 30%;
    height: 30%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
    display: none;
  }

  .logoF img {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 20%;
    display: flex;
  }

  .footer2 {
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }


  .footerImg {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: space-evenly;
  }

  .footerImg img {
    margin-left: 5px;
    margin-right: 5px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
  }

  .footerImg img:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    cursor: pointer;
  }

  .footer2 {
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .copyright {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 13px;
  }


  .footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: rgba(102, 102, 102, 0);
    width: 100%;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #232323;

  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    width: 95%;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .icons {
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-left: 10px;
  }

  .icons img {
    width: 30px;
    height: 30px;
    margin-left: 10px;
    margin-right: 10px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    z-index: 10000000;
  }

  .icons img:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    cursor: pointer;
  }

  /* CSS */
  .button-87 {
    margin: 10px;
    padding: 15px 30px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    border-radius: 10px;
    display: block;
    border: 0px;
    font-weight: 700;
    box-shadow: 0px 0px 14px -7px #f09819;
    background-image: linear-gradient(45deg, #FF512F 0%, #F09819 51%, #FF512F 100%);
    cursor: pointer;
    -ms-user-select: none;
        user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    font-size: 18px;
  }

  .button-87:hover {
    background-position: right center;
    /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
  }

  .button-87:active {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
  }

  .title {
    color: white;
    font-family: 'Passion One', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: center;
    font-size: 35px;
    -webkit-animation: changeFontColor 5s infinite alternate;
            animation: changeFontColor 5s infinite alternate;
    /* Change 5s to your desired duration */
  }


  .logoUp {
    width: 50px;
    height: 50px;
    margin-top: -8px;
    margin-right: 20px;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;
  }

  .logo2 img {
    width: 75px;
    height: 75px;
  }

  .downImg {
    width: 35px;
    -webkit-animation: arrow_down_animation 2s ease-in-out infinite;
            animation: arrow_down_animation 2s ease-in-out infinite;
    cursor: pointer;
  }

}

@media screen and (min-width: 1200px) and (max-width: 1919px) {
  .connect2-2-Mob {
    display: none;
  }

  .connectMob {
    display: none;
  }

  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-top: 100px;
    padding-bottom: 100px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 51%, rgba(0, 0, 0, 1) 100%), url(/static/media/footer.c0569fc8.jpg) no-repeat center center fixed;
    background-size: cover;
  }

  .littleNav {
    display: none;
  }

  .miniBarMain {
    display: none;
  }

  .logosMob {
    display: none;
  }

  .logos {
    width: 30%;
    height: 30%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
  }

  .logos_mobile {
    width: 30%;
    height: 30%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
    display: none;
  }

  .logoF img {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 20%;
    display: flex;
  }

  .footer2 {
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }


  .footerImg {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: space-evenly;
  }

  .footerImg img {
    margin-left: 5px;
    margin-right: 5px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
  }

  .footerImg img:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    cursor: pointer;
  }

  .footer2 {
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .copyright {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 13px;
  }


  .footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: rgba(102, 102, 102, 0);
    width: 100%;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #232323;

  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    width: 95%;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .icons {
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-left: 10px;
  }

  .icons img {
    width: 30px;
    height: 30px;
    margin-left: 10px;
    margin-right: 10px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    z-index: 10000000;
  }

  .icons img:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    cursor: pointer;
  }

  /* CSS */
  .button-87 {
    margin: 10px;
    padding: 15px 30px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    border-radius: 10px;
    display: block;
    border: 0px;
    font-weight: 700;
    box-shadow: 0px 0px 14px -7px #f09819;
    background-image: linear-gradient(45deg, #FF512F 0%, #F09819 51%, #FF512F 100%);
    cursor: pointer;
    -ms-user-select: none;
        user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    font-size: 18px;
  }

  .button-87:hover {
    background-position: right center;
    /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
  }

  .button-87:active {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
  }

  .title {
    color: white;
    font-family: 'Passion One', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: center;
    font-size: 35px;
    -webkit-animation: changeFontColor 5s infinite alternate;
            animation: changeFontColor 5s infinite alternate;
    /* Change 5s to your desired duration */
  }


  .logoUp {
    width: 50px;
    height: 50px;
    margin-top: -8px;
    margin-right: 20px;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;
  }

  .logo2 img {
    width: 75px;
    height: 75px;
  }

  .downImg {
    width: 35px;
    -webkit-animation: arrow_down_animation 2s ease-in-out infinite;
            animation: arrow_down_animation 2s ease-in-out infinite;
    cursor: pointer;
  }

}

@media screen and (min-width: 1920px) {
  .connect2-2-Mob {
    display: none;
  }

  .connectMob {
    display: none;
  }

  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-top: 150px;
    padding-bottom: 150px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 51%, rgba(0, 0, 0, 1) 100%), url(/static/media/footer.c0569fc8.jpg) no-repeat center center fixed;
    background-size: cover;
  }

  .littleNav {
    display: none;
  }

  .miniBarMain {
    display: none;
  }

  .logosMob {
    display: none;
  }

  .logos {
    width: 30%;
    height: 30%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
  }

  .logos_mobile {
    width: 30%;
    height: 30%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
    display: none;
  }

  .logoF img {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 20%;
    display: flex;
  }

  .footer2 {
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }


  .footerImg {
    width: 28px;
    height: 28px;
    display: flex;
    justify-content: space-evenly;
  }

  .footerImg img {
    margin-left: 5px;
    margin-right: 5px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
  }

  .footerImg img:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    cursor: pointer;
  }

  .footer2 {
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .copyright {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 20px;
  }

  .footer2 {
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }


  .footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: rgba(102, 102, 102, 0);
    width: 100%;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #232323;

  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    width: 95%;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .icons {
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-left: 10px;
  }

  .icons img {
    width: 45px;
    height: 45px;
    margin-left: 15px;
    margin-right: 15px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    z-index: 10000000;
  }

  .icons img:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    cursor: pointer;
  }

  .button-87 {
    margin: 10px;
    padding: 20px 40px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    border-radius: 10px;
    display: block;
    border: 0px;
    font-weight: 700;
    box-shadow: 0px 0px 14px -7px #f09819;
    background-image: linear-gradient(45deg, #FF512F 0%, #F09819 51%, #FF512F 100%);
    cursor: pointer;
    -ms-user-select: none;
        user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    font-size: 28px;
  }

  .button-87:hover {
    background-position: right center;
    /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
  }

  .button-87:active {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
  }

  .title {
    color: white;
    font-family: 'Passion One', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: center;
    font-size: 50px;
    -webkit-animation: changeFontColor 5s infinite alternate;
            animation: changeFontColor 5s infinite alternate;
    /* Change 5s to your desired duration */
  }

  .logoUp {
    width: 80px;
    height: 80px;
    margin-top: -10px;
    margin-right: 30px;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;
  }

  .logo2 img {
    width: 75px;
    height: 75px;
  }

  .downImg {
    width: 35px;
    -webkit-animation: arrow_down_animation 2s ease-in-out infinite;
            animation: arrow_down_animation 2s ease-in-out infinite;
    cursor: pointer;
  }
}

@media screen and (min-width: 1921px) and (max-width: 3839px) {
  .connect2-2-Mob {
    display: none;
  }

  .connectMob {
    display: none;
  }

  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-top: 200px;
    padding-bottom: 200px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 51%, rgba(0, 0, 0, 1) 100%), url(/static/media/footer.c0569fc8.jpg) no-repeat center center fixed;
    background-size: cover;
  }

  .littleNav {
    display: none;
  }

  .miniBarMain {
    display: none;
  }

  .logosMob {
    display: none;
  }

  .logos {
    width: 30%;
    height: 30%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
  }

  .logos_mobile {
    width: 30%;
    height: 30%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
    display: none;
  }

  .logoF img {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 20%;
    display: flex;
  }

  .footer2 {
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }


  .footerImg {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: space-evenly;
  }

  .footerImg img {
    margin-left: 10px;
    margin-right: 10px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
  }

  .footerImg img:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    cursor: pointer;
  }

  .footer2 {
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .copyright {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 27px;
  }

  .footer2 {
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }


  .footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: rgba(102, 102, 102, 0);
    width: 100%;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    width: 100%;
    padding-bottom: 30px;
    padding-top: 30px;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #232323;
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    width: 95%;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
  }

  .connect div {
    margin-left: 40px;
    margin-right: 40px;
    color: white;
    margin-top: auto;
    justify-content: space-evenly;
    display: flex;
    cursor: pointer;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;

  }

  .connect div:hover {
    -webkit-transform: scale(1.3);
            transform: scale(1.3);
  }

  .icons {
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-left: 10px;
  }

  .icons img {
    width: 55px;
    height: 55px;
    margin-left: 20px;
    margin-right: 20px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    z-index: 10000000;
  }

  .icons img:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    cursor: pointer;
  }

  .button-87 {
    margin: 10px;
    padding: 25px 40px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    border-radius: 20px;
    display: block;
    border: 0px;
    font-weight: 700;
    box-shadow: 0px 0px 14px -7px #f09819;
    background-image: linear-gradient(45deg, #FF512F 0%, #F09819 51%, #FF512F 100%);
    cursor: pointer;
    -ms-user-select: none;
        user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    font-size: 37px;
  }

  .button-87:hover {
    background-position: right center;
    /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
  }

  .button-87:active {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
  }

  .title {
    color: white;
    font-family: 'Passion One', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: center;
    font-size: 70px;
    -webkit-animation: changeFontColor 5s infinite alternate;
            animation: changeFontColor 5s infinite alternate;
    /* Change 5s to your desired duration */
  }

  .logoUp {
    width: 125px;
    height: 125px;
    margin-top: -30px;
    margin-right: 50px;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;
  }

  .logo2 img {
    width: 75px;
    height: 75px;
  }

  .downImg {
    width: 35px;
    -webkit-animation: arrow_down_animation 2s ease-in-out infinite;
            animation: arrow_down_animation 2s ease-in-out infinite;
    cursor: pointer;
  }
}

@media screen and (min-width: 3840px) {
  .connect2-2-Mob {
    display: none;
  }

  .connectMob {
    display: none;
  }

  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-top: 300px;
    padding-bottom: 300px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 51%, rgba(0, 0, 0, 1) 100%), url(/static/media/footer.c0569fc8.jpg) no-repeat center center fixed;
    background-size: cover;
  }

  .littleNav {
    display: none;
  }

  .miniBarMain {
    display: none;
  }

  .logosMob {
    display: none;
  }

  .logos {
    width: 30%;
    height: 30%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
  }

  .logos_mobile {
    width: 30%;
    height: 30%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
    display: none;
  }

  .logoF img {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 20%;
    display: flex;
  }

  .footer2 {
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .footerImg {
    width: 55px;
    height: 55px;
    display: flex;
    justify-content: space-evenly;
  }

  .footerImg img {
    margin-left: 15px;
    margin-right: 15px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
  }

  .footerImg img:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    cursor: pointer;
  }

  .footer2 {
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .copyright {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 40px;
  }

  .footer2 {
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }


  .footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: rgba(102, 102, 102, 0);
    width: 100%;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-bottom: 40px;
    padding-top: 40px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #232323;

  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    width: 95%;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
  }

  .connect div {
    margin-left: 55px;
    margin-right: 55px;
    color: white;
    margin-top: auto;
    justify-content: space-evenly;
    display: flex;
    cursor: pointer;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;


  }

  .connect div:hover {
    -webkit-transform: scale(1.3);
            transform: scale(1.3);
  }

  .icons {
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-left: 10px;
  }

  .icons img {
    width: 80px;
    height: 80px;
    margin-left: 30px;
    margin-right: 30px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    z-index: 10000000;
  }

  .icons img:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    cursor: pointer;
  }

  .icons img:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    cursor: pointer;
  }

  .button-87 {
    margin: 10px;
    padding: 40px 50px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    border-radius: 30px;
    display: block;
    border: 0px;
    font-weight: 700;
    box-shadow: 0px 0px 14px -7px #f09819;
    background-image: linear-gradient(45deg, #FF512F 0%, #F09819 51%, #FF512F 100%);
    cursor: pointer;
    -ms-user-select: none;
        user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    font-size: 55px;
  }

  .button-87:hover {
    background-position: right center;
    /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
  }

  .button-87:active {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
  }

  .title {
    color: white;
    font-family: 'Passion One', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: center;
    font-size: 95px;
    -webkit-animation: changeFontColor 5s infinite alternate;
            animation: changeFontColor 5s infinite alternate;
    /* Change 5s to your desired duration */
  }

  .logoUp {
    width: 180px;
    height: 180px;
    margin-top: -45px;
    margin-right: 65px;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;
  }

  .logo2 img {
    width: 75px;
    height: 75px;
  }

  .downImg {
    width: 35px;
    -webkit-animation: arrow_down_animation 2s ease-in-out infinite;
            animation: arrow_down_animation 2s ease-in-out infinite;
    cursor: pointer;
  }
}

@-webkit-keyframes changeBorderColor {
  0% {
    border-color: #ee5849; /* Initial color */
  }
  25% {
    border-color: #ffcc00; /* Change color at 25% */
  }
  50% {
    border-color: #00cc66; /* Change color at 50% */
  }
  75% {
    border-color: #3399ff; /* Change color at 75% */
  }
  100% {
    border-color: #ee5849; /* Back to initial color */
  }
}

@keyframes changeBorderColor {
  0% {
    border-color: #ee5849; /* Initial color */
  }
  25% {
    border-color: #ffcc00; /* Change color at 25% */
  }
  50% {
    border-color: #00cc66; /* Change color at 50% */
  }
  75% {
    border-color: #3399ff; /* Change color at 75% */
  }
  100% {
    border-color: #ee5849; /* Back to initial color */
  }
}


@-webkit-keyframes changeBorderColor2 {
  0% {
    border-color: rgb(0, 131, 50); /* Initial color */
  }
  25% {
    border-color: #ff5e00; /* Change color at 25% */
  }
  50% {
    border-color: #0052cc; /* Change color at 50% */
  }
  75% {
    border-color: #c20563; /* Change color at 75% */
  }
  100% {
    border-color: rgb(114, 95, 55); /* Back to initial color */
  }
}


@keyframes changeBorderColor2 {
  0% {
    border-color: rgb(0, 131, 50); /* Initial color */
  }
  25% {
    border-color: #ff5e00; /* Change color at 25% */
  }
  50% {
    border-color: #0052cc; /* Change color at 50% */
  }
  75% {
    border-color: #c20563; /* Change color at 75% */
  }
  100% {
    border-color: rgb(114, 95, 55); /* Back to initial color */
  }
}

@media screen and (max-width: 499px) {

  .claimIcon {
    width: 22px;
    height: 22px;
    margin-top: -5px;
  }

  .stakeMainDiv {
    background-color: black;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
    background: url(/static/media/bg.16cea970.jpg) no-repeat center center fixed;
    background-size: cover;
    overflow: hidden;
  }

  .btns {

    width: 150px;
    background-color: #facc15;
    color: rgb(0, 0, 0);
    padding: 5px;
    font-weight: 500;
    margin-bottom: 5%;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;

  }

  .btnsDis {
    width: 150px;
    background-color: #77946c;
    border: none;
    color: rgb(255, 255, 255);
    padding: 5px;
    font-weight: 500;
    margin-bottom: 5%;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    cursor: no-drop;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  }

  .btns2 {
    width: 150px;
    color: rgb(0, 0, 0);
    padding: 5px;
    font-weight: 500;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    background-color: rgb(238, 62, 62);
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    border: none;
  }

  .btns2Dis {
    width: 150px;
    background-color: rgb(196, 134, 134);
    cursor: no-drop;
    color: rgb(255, 255, 255);
    padding: 5px;
    font-weight: 500;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  }

  .btns:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }

  .btns2:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }

  .btnsMain {
    display: flex;
    flex-flow: column nowrap;
    margin-top: auto;
    margin-bottom: auto;
    display: none;
  }

  .btnsMainBig {
    display: flex;
    flex-flow: column nowrap;
    margin-top: auto;
    margin-bottom: auto;
  }

  .markBar {
    background-color: #000000;
    margin-top: 2%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    background-color: #00000000;
    background-size: contain;
    padding: 15px;

  }

  .markBarCon {
    border-radius: 20px;
    border: 1px solid white;
    display: flex;
    flex-flow: row nowrap;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding: 15px;
    margin-top: 20px;
    margin-bottom: 20px;
    background-color: #000000;
    border-radius: 20px;
    border: 2px solid white;
  }

  .markBar2 {
    flex-flow: column nowrap;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: -10%;
  }

  .num {
    font-size: 16px;
    color: #ffffff;

    text-align: center;
  }

  .name {
    color: #ffffff;
    font-size: 13px;
    text-align: center;
    font-family: 'Russo One', sans-serif;
  }

  .nameClaim {
    background-color: rgb(87, 163, 15);
    border: 1px solid rgb(166, 253, 84);
    color: rgb(255, 255, 255);
    border-radius: 5px;
    transition: 0.5s ease-in-out;
    font-size: 13px;
    text-align: center;
    font-family: 'Russo One', sans-serif;
  }


  .nameClaim2 {
    background-color: rgb(79, 158, 4);
    border: 1px solid rgb(181, 250, 117);
    color: rgb(255, 255, 255);
    border-radius: 5px;
    transition: 0.5s ease-in-out;
    font-size: 13px;
    text-align: center;
    font-family: 'Russo One', sans-serif;
    cursor: not-allowed;
  }

  .num2 {
    font-size: 16px;
    color: #e02712;
    margin-top: 15px;
    text-align: center;
  }

  .name2 {
    color: black;
    font-size: 16px;
    text-align: center;
    font-weight: 500;
  }

  .markBar img {
    width: 70px;
    height: 70px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 20%;
  }

  .markBarMain {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: 3%;
    margin-bottom: 5%;
  }

  .stakeMain {
    display: flex;
    flex-flow: column nowrap;
    overflow: hidden;
  }

  .stakeMain2 {
    border-right: 20px #f3ba2f solid;
    border-top: #f3ba2f 20px solid;
  }

  .sticky {
    width: 20%;

    bottom: 0;
    right: 90%;
    margin-top: -10%;
  }

  .items {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

  .board {
    margin-bottom: 5%;
    padding: 35px;
    border: #e02712 5px solid;
    background-color: #fffa00;
    border-radius: 50px;
    margin-left: 1%;
    margin-right: 1%;
    width: 90%;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  .boardMain {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 100%;

    margin-bottom: 7%;

  }

  .nameStake {
    width: 50%;
  }

  .carrots {
    width: 90px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .boardImg {
    background-color: #ffb963;

  }

  .boardPics {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    margin-bottom: 8%;
  }

  .approveBtns {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    margin-top: 2%;
  }

  .approveBtns1 {
    border: 4px solid black;
    box-shadow: 0 0 5px gold;
    color: rgb(255, 255, 255);
    padding: 15px;
    border-radius: 20px;
    font-size: 23px;
    font-family: 'Russo One', sans-serif;
    width: 270px;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    background-color: #0a74ca;
  }

  .approveBtns1Dis {
    border: 4px solid black;
    box-shadow: 0 0 5px gold;
    color: rgb(255, 255, 255);
    padding: 15px;
    border-radius: 20px;
    font-size: 23px;
    font-family: 'Russo One', sans-serif;
    width: 270px;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    background-color: #0a74ca;
    cursor: no-drop;
  }

  .approveBtns1:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }

  .approveBtns2:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }

  .approveBtns3:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }

  .approveBtns3 {
    border: 4px solid black;
    box-shadow: 0 0 5px gold;
    color: rgb(255, 255, 255);
    padding: 15px;
    border-radius: 20px;
    font-size: 23px;
    font-family: 'Russo One', sans-serif;
    width: 270px;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    background-color: #f51761;
  }

  .approveBtns3Dis {
    border: 4px solid black;
    box-shadow: 0 0 5px gold;
    color: rgb(255, 255, 255);
    padding: 15px;
    border-radius: 20px;
    font-size: 23px;
    font-family: 'Russo One', sans-serif;
    width: 270px;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    background-color: #f51761;
    cursor: no-drop;
  }

  .approveBtns2 {
    background: none;
    color: white;
    padding: 20px;
    border-radius: 10px;
    font-size: 23px;
    border: 4px #70512b solid;

    width: 200px;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
  }


  .wallet3 {

    background-color: black;
    border: solid 3px;
    color: rgb(255, 255, 255);
    padding: 15px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 18px;
    margin-left: auto;
    margin-right: auto;
    width: 250px;
    transition: -webkit-transform .5s;
    transition: transform .5s;
    transition: transform .5s, -webkit-transform .5s;
    z-index: 1;
    margin-bottom: 10%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    -webkit-animation: changeBorderColor2 5s infinite alternate;
            animation: changeBorderColor2 5s infinite alternate;

  }

  .lookDown {
    width: 20px;
    height: 20px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }


  .wallet3:hover {
    cursor: pointer;
    cursor: pointer;
  }

  .wallet3::after {
    transition: all 0.8s
  }

  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }

  .images_show {
    width: 90%;
  }

  .hide {
    display: none;
  }

  .stake {
    display: block;
    background-color: red;
    color: white;
    border-radius: 10px;
    border: none;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }

  .stake2 {
    display: block;
    background-color: red;
    color: white;
    border-radius: 10px;
    border: none;
    width: -webkit-max-content;
    width: max-content;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-top: 10px;
    text-align: center;
  }

  .stake3 {
    display: block;
    background-color: red;
    color: white;
    border-radius: 10px;
    border: none;
    width: -webkit-max-content;
    width: max-content;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-bottom: 10px;
    text-align: center;
  }

  

  .tokenIds {
    display: flex;
    justify-content: center;
    color: white;
    flex-flow: row wrap;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    height: -webkit-max-content;
    height: max-content;
    margin-bottom: 10%;
  }

  .tokenIds2 {
    display: flex;
    justify-content: center;
    color: white;
    flex-flow: row wrap;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 55%;
  }

  .tS {
    color: white;
    text-align: center;
    margin-top: 5%;
    font-size: 20px;
    font-family: 'Passion One', sans-serif;
  }

  .unstakeImg {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;

  }

  .imagesAndBtn {
    justify-content: center;
  }

  .flip-card {
    background-color: transparent;
    width: 150px;
    -webkit-perspective: 1000px;
            perspective: 1000px;
    background-color: #e0706c;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;

    margin-top: 10%;
  }

  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: -webkit-transform 0.6s;
    transition: transform 0.6s;
    transition: transform 0.6s, -webkit-transform 0.6s;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  .flip-card:hover .flip-card-inner {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }

  .flip-card-front,
  .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .btnCenter {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .flip-card-front {
    /* background-color: #bbb;*/
    color: black;
  }

  .flip-card-back {
    /*background-color: #2980b9;*/
    color: white;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }

  .loginFormNote {
    height: 90%;
    width: 90%;
    background-color: rgba(255, 255, 255, 0.13);
    position: absolute;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    border-radius: 10px;
    -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
    border: 2px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
    padding: 50px 35px;
  }

  .formMain2 {
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.856);
    position: fixed;
    width: 50%;
    height: 80vh;
    top: 50%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow-x: hidden;
    border-radius: 10px;
    z-index: 100000;
    border: white 3px solid;
    padding: 10px;
  }

  .formMain {
    z-index: 10000000000000000;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.856);
    position: fixed;
    width: 80%;
    height: 80vh;
    top: 50%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow-x: hidden;
    border-radius: 10px;
    z-index: 100000;
    border: white 3px solid;

  }

  .formMain {
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.856);
    position: fixed;
    width: 80%;
    height: 80vh;
    top: 50%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow-x: hidden;
    border-radius: 10px;
    z-index: 100000;
    border: white 3px solid;

  }

  .formMain1 {
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 100000;

    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    background-color: rgba(0, 0, 0, 0.857);
  }

  .ownNFT {
    margin-bottom: 55%;
  }

  .elementName {
    font-weight: 500;
    color: red;
    font-size: 20px;
    text-align: center;
  }

  .elementName2 {
    font-weight: 500;
    color: rgb(255, 255, 255);
    font-size: 16px;
    margin-top: 5px;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .elementName3 {
    font-weight: 500;
    color: red;
    font-size: 20px;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .medalDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .closeNote {
    position: absolute;
    right: 0;
    top: 1%;
    color: rgba(255, 255, 255, 0.836);
    font-size: 30px;

    background-color: rgba(172, 67, 67, 0);
    width: 100px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    cursor: pointer;
    border: none;
  }

  .closeNote:hover,
  .closeNote:focus {
    color: rgb(71, 70, 70);
  }

  .rewards {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;

  }

  .discordB {
    display: none;
  }

  .medal {
    width: 35px;
    height: 35px;
  }

  .stakebtn:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }

  .stakebtn2:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }

  .stakebtnDiv {

    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .nftImg {
    width: 80px;
    margin-top: 20%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 2%;
  }

  .nftImgLogo {
    width: 80px;
    margin-top: 20%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    
    margin-bottom: 2%;
  }

  .nftImg2 {
    width: 80px;
    margin-top: 20%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .stakeT {
    color: #000000;
    font-family: 'Passion One', sans-serif;
    font-size: 45px;
  }
}

@media screen and (min-width: 500px) and (max-width: 767px) {

  .stakeMainDiv {
    background-color: black;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
    background: url(/static/media/bg.16cea970.jpg) no-repeat center center fixed;
    background-size: cover;
    overflow: hidden;
  }

  .btns {

    width: 150px;
    background-color: #facc15;
    color: rgb(0, 0, 0);
    padding: 5px;
    font-weight: 500;
    margin-bottom: 5%;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;

  }

  .claimIcon {
    width: 22px;
    height: 22px;
    margin-top: -5px;
  }

  .btnsDis {
    width: 150px;
    background-color: #77946c;
    border: none;
    color: rgb(255, 255, 255);
    padding: 5px;
    font-weight: 500;
    margin-bottom: 5%;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    cursor: no-drop;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  }

  .btns2 {
    width: 150px;
    color: rgb(0, 0, 0);
    padding: 5px;
    font-weight: 500;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    background-color: rgb(238, 62, 62);
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    border: none;
  }

  .btns2Dis {
    width: 150px;
    background-color: rgb(196, 134, 134);
    cursor: no-drop;
    color: rgb(255, 255, 255);
    padding: 5px;
    font-weight: 500;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  }

  .btns:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }

  .btns2:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }

  .btnsMain {
    display: flex;
    flex-flow: column nowrap;
    margin-top: auto;
    margin-bottom: auto;
    display: none;
  }

  .btnsMainBig {
    display: flex;
    flex-flow: column nowrap;
    margin-top: auto;
    margin-bottom: auto;
  }

  .markBar {
    background-color: #000000;
    margin-top: 2%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    background-color: #00000000;
    background-size: contain;
    padding: 15px;

  }

  .markBarCon {
    display: flex;
    flex-flow: row nowrap;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding: 15px;
    margin-top: 20px;
    margin-bottom: 20px;
    background-color: #000000;
    border-radius: 20px;
    border: 2px solid white;
  }

  .markBar2 {
    flex-flow: column nowrap;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: -10%;
  }

  .num {
    font-size: 14px;
    color: #ffffff;

    text-align: center;
  }

  .name {
    color: #ffffff;
    font-size: 16px;
    text-align: center;
    font-family: 'Russo One', sans-serif;
  }

  .nameClaim {
    background-color: rgb(87, 163, 15);
    border: 1px solid rgb(166, 253, 84);
    color: rgb(255, 255, 255);
    border-radius: 5px;
    transition: 0.5s ease-in-out;
    font-size: 16px;
    text-align: center;
    font-family: 'Russo One', sans-serif;
  }


  .nameClaim2 {
    background-color: rgb(79, 158, 4);
    border: 1px solid rgb(181, 250, 117);
    color: rgb(255, 255, 255);
    border-radius: 5px;
    transition: 0.5s ease-in-out;
    font-size: 16px;
    text-align: center;
    font-family: 'Russo One', sans-serif;
    cursor: not-allowed;
  }

  .num2 {
    font-size: 20px;
    color: #e02712;
    margin-top: 15px;
    text-align: center;
  }

  .name2 {
    color: black;
    font-size: 18px;
    text-align: center;
    font-weight: 500;
  }

  .markBar img {
    width: 70px;
    height: 70px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 20%;
  }

  .markBarMain {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: 3%;
    margin-bottom: 5%;
  }

  .stakeMain {
    display: flex;
    flex-flow: column nowrap;
    overflow: hidden;
  }

  .stakeMain2 {
    border-right: 20px #f3ba2f solid;
    border-top: #f3ba2f 20px solid;
  }

  .sticky {
    width: 20%;

    bottom: 0;
    right: 90%;
    margin-top: -10%;
  }

  .items {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

  .board {
    margin-bottom: 5%;
    padding: 35px;
    border: #e02712 5px solid;
    background-color: #fffa00;
    border-radius: 50px;
    margin-left: 1%;
    margin-right: 1%;
    width: 90%;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  .boardMain {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 100%;

    margin-bottom: 7%;

  }

  .nameStake {
    width: 50%;
  }

  .carrots {
    width: 90px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .boardPics {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    margin-bottom: 8%;
  }

  .approveBtns {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    margin-top: 2%;
  }

  .approveBtns1 {
    border: 4px solid black;
    box-shadow: 0 0 5px gold;
    color: rgb(255, 255, 255);
    padding: 15px;
    border-radius: 20px;
    font-size: 23px;
    font-family: 'Russo One', sans-serif;
    width: 350px;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    background-color: #0a74ca;
  }

  .approveBtns1Dis {
    border: 4px solid black;
    box-shadow: 0 0 5px gold;
    color: rgb(255, 255, 255);
    padding: 15px;
    border-radius: 20px;
    font-size: 23px;
    font-family: 'Russo One', sans-serif;
    width: 350px;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    background-color: #0a74ca;
    cursor: no-drop;
  }

  .approveBtns1:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }

  .approveBtns2:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }

  .approveBtns3:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }

  .approveBtns3 {
    border: 4px solid black;
    box-shadow: 0 0 5px gold;
    color: rgb(255, 255, 255);
    padding: 15px;
    border-radius: 20px;
    font-size: 23px;
    font-family: 'Russo One', sans-serif;
    width: 350px;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    background-color: #f51761;
  }

  .approveBtns3Dis {
    border: 4px solid black;
    box-shadow: 0 0 5px gold;
    color: rgb(255, 255, 255);
    padding: 15px;
    border-radius: 20px;
    font-size: 23px;
    font-family: 'Russo One', sans-serif;
    width: 350px;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    background-color: #f51761;
    cursor: no-drop;
  }

  .approveBtns2 {
    background: none;
    color: white;
    padding: 20px;
    border-radius: 10px;
    font-size: 23px;
    border: 4px #70512b solid;

    width: 200px;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
  }


  .wallet3 {

    background-color: black;
    border: solid 3px;
    color: rgb(255, 255, 255);
    padding: 15px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    margin-left: auto;
    margin-right: auto;
    width: 270px;
    transition: -webkit-transform .5s;
    transition: transform .5s;
    transition: transform .5s, -webkit-transform .5s;
    z-index: 1;
    margin-bottom: 10%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    -webkit-animation: changeBorderColor2 5s infinite alternate;
            animation: changeBorderColor2 5s infinite alternate;

  }

  .lookDown {
    width: 20px;
    height: 20px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }


  .wallet3:hover {
    cursor: pointer;
  }

  .wallet3::after {
    transition: all 0.8s
  }

  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }

  .images_show {
    width: 90%;
  }

  .hide {
    display: none;
  }

  .stake {
    display: block;
    background-color: red;
    color: white;
    border-radius: 10px;
    border: none;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }

  .stake2 {
    display: block;
    background-color: red;
    color: white;
    border-radius: 10px;
    border: none;
    width: -webkit-max-content;
    width: max-content;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-top: 10px;
    text-align: center;
  }

  .stake3 {
    display: block;
    background-color: red;
    color: white;
    border-radius: 10px;
    border: none;
    width: -webkit-max-content;
    width: max-content;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-bottom: 10px;
    text-align: center;
  }

  

  .tokenIds {
    display: flex;
    justify-content: center;
    color: white;
    flex-flow: row wrap;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    height: -webkit-max-content;
    height: max-content;
    margin-bottom: 10%;
  }

  .tokenIds2 {
    display: flex;
    justify-content: center;
    color: white;
    flex-flow: row wrap;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40%;
  }


  .tS {
    color: white;
    text-align: center;
    margin-top: 2%;
    font-size: 30px;
    font-family: 'Passion One', sans-serif;
  }

  .unstakeImg {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;

  }

  .imagesAndBtn {
    justify-content: center;
  }

  .flip-card {
    background-color: transparent;
    width: 150px;
    -webkit-perspective: 1000px;
            perspective: 1000px;
    background-color: #e0706c;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;

    margin-top: 10%;
  }

  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: -webkit-transform 0.6s;
    transition: transform 0.6s;
    transition: transform 0.6s, -webkit-transform 0.6s;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  .flip-card:hover .flip-card-inner {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }

  .flip-card-front,
  .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .btnCenter {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .flip-card-front {
    /* background-color: #bbb;*/
    color: black;
  }

  .flip-card-back {
    /*background-color: #2980b9;*/
    color: white;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }

  .loginFormNote {
    height: 90%;
    width: 90%;
    background-color: rgba(255, 255, 255, 0.13);
    position: absolute;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    border-radius: 10px;
    -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
    border: 2px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
    padding: 50px 35px;
  }

  .formMain {
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.856);
    position: fixed;
    width: 80%;
    height: 80vh;
    top: 50%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow-x: hidden;
    border-radius: 10px;
    z-index: 100000;
    border: white 3px solid;
  }

  .formMain {
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.856);
    position: fixed;
    width: 80%;
    height: 80vh;
    top: 50%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow-x: hidden;
    border-radius: 10px;
    z-index: 100000;
    border: white 3px solid;
  }

  .formMain1 {
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 100000;

    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    background-color: rgba(0, 0, 0, 0.857);
  }

  .ownNFT {
    margin-bottom: 40%;
  }

  .elementName {
    font-weight: 500;
    color: red;
    font-size: 20px;
    text-align: center;
  }

  .elementName2 {
    font-weight: 500;
    color: rgb(255, 255, 255);
    font-size: 16px;
    margin-top: 5px;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .elementName3 {
    font-weight: 500;
    color: red;
    font-size: 20px;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .medalDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .closeNote {
    position: absolute;
    right: 0;
    top: 1%;
    color: rgba(255, 255, 255, 0.836);
    font-size: 30px;

    background-color: rgba(172, 67, 67, 0);
    width: 100px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    cursor: pointer;
    border: none;
  }

  .closeNote:hover,
  .closeNote:focus {
    color: rgb(71, 70, 70);
  }

  .rewards {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;

  }

  .discordB {
    display: none;
  }

  .medal {
    width: 35px;
    height: 35px;
  }

  .stakebtn:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }

  .stakebtn2:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }

  .stakebtnDiv {

    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .nftImg {
    width: 110px;
    margin-top: 20%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 2%;
  }

  .nftImgLogo {
    width: 110px;
    margin-top: 20%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    
    margin-bottom: 2%;
  }

  .nftImg2 {
    width: 110px;
    margin-top: 20%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .stakeT {
    color: #000000;
    font-family: 'Passion One', sans-serif;    
    font-size: 45px;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {

  .stakeMainDiv {
    background-color: black;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
    background: url(/static/media/bg.16cea970.jpg) no-repeat center center fixed;
    background-size: cover;
  }

  .btns {

    width: 150px;
    background-color: #facc15;
    color: rgb(0, 0, 0);
    padding: 5px;
    font-weight: 500;
    margin-bottom: 5%;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;

  }

  .claimIcon {
    width: 22px;
    height: 22px;
    margin-top: -5px;
  }

  .btnsDis {
    width: 150px;
    background-color: #77946c;
    border: none;
    color: rgb(255, 255, 255);
    padding: 5px;
    font-weight: 500;
    margin-bottom: 5%;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    cursor: no-drop;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  }

  .btns2 {
    width: 150px;
    color: rgb(0, 0, 0);
    padding: 5px;
    font-weight: 500;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    background-color: rgb(238, 62, 62);
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    border: none;
  }

  .btns2Dis {
    width: 150px;
    background-color: rgb(196, 134, 134);
    cursor: no-drop;
    color: rgb(255, 255, 255);
    padding: 5px;
    font-weight: 500;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  }

  .btns:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }

  .btns2:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }

  .btnsMain {
    display: flex;
    flex-flow: column nowrap;
    margin-top: auto;
    margin-bottom: auto;
    display: none;
  }

  .btnsMainBig {
    display: flex;
    flex-flow: column nowrap;
    margin-top: auto;
    margin-bottom: auto;
  }

  .markBar {
    background-color: #000000;
    margin-top: 2%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    background-color: #00000000;
    background-size: contain;
    padding: 15px;

  }

  .markBarCon {
    display: flex;
    flex-flow: row nowrap;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding: 15px;
    margin-top: 20px;
    margin-bottom: 20px;
    background-color: #000000;
    border-radius: 20px;
    border: 2px solid white;
  }

  .markBar2 {
    flex-flow: column nowrap;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: -10%;
  }

  .num {
    font-size: 15px;
    color: #ffffff;

    text-align: center;
  }

  .name {
    color: #ffffff;
    font-size: 17px;
    text-align: center;
    font-family: 'Russo One', sans-serif;
  }

  .nameClaim {
    background-color: rgb(87, 163, 15);
    border: 1px solid rgb(166, 253, 84);
    color: rgb(255, 255, 255);
    border-radius: 5px;
    transition: 0.5s ease-in-out;
    font-size: 17px;
    text-align: center;
    font-family: 'Russo One', sans-serif;
  }


  .nameClaim2 {
    background-color: rgb(79, 158, 4);
    border: 1px solid rgb(181, 250, 117);
    color: rgb(255, 255, 255);
    border-radius: 5px;
    transition: 0.5s ease-in-out;
    font-size: 17px;
    text-align: center;
    font-family: 'Russo One', sans-serif;
    cursor: not-allowed;
  }

  .num2 {
    font-size: 20px;
    color: #e02712;
    margin-top: 15px;
    text-align: center;
  }

  .name2 {
    color: black;
    font-size: 18px;
    text-align: center;
    font-weight: 500;
  }

  .markBar img {
    width: 70px;
    height: 70px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 20%;
  }

  .markBarMain {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: 3%;
    margin-bottom: 5%;
  }

  .stakeMain {
    display: flex;
    flex-flow: column nowrap;
    overflow: hidden;
  }

  .stakeMain2 {
    border-right: 20px #f3ba2f solid;
    border-top: #f3ba2f 20px solid;
  }

  .sticky {
    width: 20%;

    bottom: 0;
    right: 90%;
    margin-top: -10%;
  }

  .items {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

  .board {
    margin-bottom: 5%;
    padding: 35px;
    border: #e02712 7px solid;
    background-color: #fffa00;
    border-radius: 50px;
    margin-left: 1%;
    margin-right: 1%;
    width: 65%;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  .boardMain {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 100%;

    margin-bottom: 7%;

  }

  .nameStake {
    width: 50%;
  }

  .carrots {
    width: 90px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .boardPics {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    margin-bottom: 8%;
  }

  .approveBtns {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    margin-top: 2%;
  }

  .approveBtns1 {
    border: 4px solid black;
    box-shadow: 0 0 5px gold;
    color: rgb(255, 255, 255);
    padding: 15px;
    border-radius: 20px;
    font-size: 23px;
    font-family: 'Russo One', sans-serif;
    width: 400px;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    background-color: #0a74ca;
  }

  .approveBtns1Dis {
    border: 4px solid black;
    box-shadow: 0 0 5px gold;
    color: rgb(255, 255, 255);
    padding: 15px;
    border-radius: 20px;
    font-size: 23px;
    font-family: 'Russo One', sans-serif;
    width: 400px;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    background-color: #0a74ca;
    cursor: no-drop;
  }

  .approveBtns1:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }

  .approveBtns2:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }

  .approveBtns3:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }

  .approveBtns3 {
    border: 4px solid black;
    box-shadow: 0 0 5px gold;
    color: rgb(255, 255, 255);
    padding: 15px;
    border-radius: 20px;
    font-size: 23px;
    font-family: 'Russo One', sans-serif;
    width: 400px;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    background-color: #f51761;
  }

  .approveBtns3Dis {
    cursor: no-drop;
    border: 4px solid black;
    box-shadow: 0 0 5px gold;
    color: rgb(255, 255, 255);
    padding: 15px;
    border-radius: 20px;
    font-size: 23px;
    font-family: 'Russo One', sans-serif;
    width: 400px;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    background-color: #f51761;
  }

  .approveBtns2 {
    background: none;
    color: white;
    padding: 20px;
    border-radius: 10px;
    font-size: 23px;
    border: 4px #70512b solid;

    width: 200px;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
  }


  .wallet3 {

    background-color: black;
    border: solid 3px;
    color: rgb(255, 255, 255);
    padding: 15px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    margin-left: auto;
    margin-right: auto;
    width: 270px;
    transition: -webkit-transform .5s;
    transition: transform .5s;
    transition: transform .5s, -webkit-transform .5s;
    z-index: 1;
    margin-bottom: 10%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    -webkit-animation: changeBorderColor2 5s infinite alternate;
            animation: changeBorderColor2 5s infinite alternate;

  }

  .lookDown {
    width: 20px;
    height: 20px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }


  .wallet3:hover {
    cursor: pointer;
  }

  .wallet3::after {
    transition: all 0.8s
  }

  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }

  .images_show {
    width: 90%;
  }

  .hide {
    display: none;
  }

  .stake {
    display: block;
    background-color: red;
    color: white;
    border-radius: 10px;
    border: none;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }

  .stake2 {
    display: block;
    background-color: red;
    color: white;
    border-radius: 10px;
    border: none;
    width: -webkit-max-content;
    width: max-content;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-top: 10px;
    text-align: center;
  }

  .stake3 {
    display: block;
    background-color: red;
    color: white;
    border-radius: 10px;
    border: none;
    width: -webkit-max-content;
    width: max-content;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-bottom: 10px;
    text-align: center;
  }

  

  .tokenIds {
    display: flex;
    justify-content: center;
    color: white;
    flex-flow: row wrap;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    height: -webkit-max-content;
    height: max-content;
    margin-bottom: 10%;
  }

  .tokenIds2 {
    display: flex;
    justify-content: center;
    color: white;
    flex-flow: row wrap;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 25%;
  }

  .tS {
    color: white;
    text-align: center;
    margin-top: 2%;
    font-size: 40px;
    font-family: 'Passion One', sans-serif;
  }

  .unstakeImg {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;

  }

  .imagesAndBtn {
    justify-content: center;
  }

  .flip-card {
    background-color: transparent;
    width: 150px;
    -webkit-perspective: 1000px;
            perspective: 1000px;
    background-color: #e0706c;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;

    margin-top: 10%;
  }

  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: -webkit-transform 0.6s;
    transition: transform 0.6s;
    transition: transform 0.6s, -webkit-transform 0.6s;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  .flip-card:hover .flip-card-inner {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }

  .flip-card-front,
  .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .btnCenter {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .flip-card-front {
    /* background-color: #bbb;*/
    color: black;
  }

  .flip-card-back {
    /*background-color: #2980b9;*/
    color: white;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }

  .loginFormNote {
    height: 90%;
    width: 90%;
    background-color: rgba(255, 255, 255, 0.13);
    position: absolute;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    border-radius: 10px;
    -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
    border: 2px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
    padding: 50px 35px;
  }

  .formMain {
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.856);
    position: fixed;
    width: 80%;
    height: 80vh;
    top: 50%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow-x: hidden;
    border-radius: 10px;
    z-index: 100000;
    border: white 3px solid;
  }

  .formMain {
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.856);
    position: fixed;
    width: 80%;
    height: 80vh;
    top: 50%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow-x: hidden;
    border-radius: 10px;
    z-index: 100000;
    border: white 3px solid;
  }

  .formMain1 {
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 100000;

    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    background-color: rgba(0, 0, 0, 0.857);
  }

  .ownNFT {
    margin-top: 18%;
    margin-bottom: 5.5%;
  }

  .elementName {
    font-weight: 500;
    color: red;
    font-size: 20px;
    text-align: center;
  }

  .elementName2 {
    font-weight: 500;
    color: rgb(255, 255, 255);
    font-size: 16px;
    margin-top: 5px;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .elementName3 {
    font-weight: 500;
    color: red;
    font-size: 20px;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .medalDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .closeNote {
    position: absolute;
    right: 0;
    top: 1%;
    color: rgba(255, 255, 255, 0.836);
    font-size: 30px;

    background-color: rgba(172, 67, 67, 0);
    width: 100px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    cursor: pointer;
    border: none;
  }

  .closeNote:hover,
  .closeNote:focus {
    color: rgb(71, 70, 70);
  }

  .rewards {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;

  }

  .discordB {
    display: none;
  }

  .medal {
    width: 35px;
    height: 35px;
  }

  .stakebtn:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }

  .stakebtn2:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }

  .stakebtnDiv {

    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .nftImg {
    width: 110px;
    margin-top: 20%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 2%;
  }

  .nftImgLogo {
    width: 110px;
    margin-top: 20%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    
    margin-bottom: 2%;
  }

  .nftImg2 {
    width: 110px;
    margin-top: 20%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .stakeT {
    color: #000000;
    font-family: 'Passion One', sans-serif;
    font-size: 45px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {

  .stakeMainDiv {
    background-color: black;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
    background: url(/static/media/bg.16cea970.jpg) no-repeat center center fixed;
    background-size: cover;
  }

  .btns {

    width: 150px;
    background-color: #facc15;
    color: rgb(0, 0, 0);
    padding: 5px;
    font-weight: 500;
    margin-bottom: 5%;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;

  }

  .claimIcon {
    width: 22px;
    height: 22px;
    margin-top: -5px;
  }

  .btnsDis {
    width: 150px;
    background-color: #77946c;
    border: none;
    color: rgb(255, 255, 255);
    padding: 5px;
    font-weight: 500;
    margin-bottom: 5%;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    cursor: no-drop;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  }

  .btns2 {
    width: 150px;
    color: rgb(0, 0, 0);
    padding: 5px;
    font-weight: 500;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    background-color: rgb(238, 62, 62);
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    border: none;
  }

  .btns2Dis {
    width: 150px;
    background-color: rgb(196, 134, 134);
    cursor: no-drop;
    color: rgb(255, 255, 255);
    padding: 5px;
    font-weight: 500;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  }

  .btns:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }

  .btns2:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }

  .btnsMain {
    display: flex;
    flex-flow: column nowrap;
    margin-top: auto;
    margin-bottom: auto;
    display: none;
  }

  .btnsMainBig {
    display: flex;
    flex-flow: column nowrap;
    margin-top: auto;
    margin-bottom: auto;
  }

  .markBar {
    background-color: #000000;
    margin-top: 2%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    background-color: #00000000;
    background-size: contain;
    padding: 15px;

  }

  .markBarCon {
    display: flex;
    flex-flow: row nowrap;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding: 15px;
    margin-top: 20px;
    margin-bottom: 20px;
    background-color: #000000;
    border-radius: 20px;
    border: 2px solid white;
  }

  .markBar2 {
    flex-flow: column nowrap;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: -10%;
  }

  .num {
    font-size: 15px;
    color: #ffffff;

    text-align: center;
  }

  .name {
    color: #ffffff;
    font-size: 17px;
    text-align: center;
    font-family: 'Russo One', sans-serif;
  }

  .nameClaim {
    background-color: rgb(87, 163, 15);
    border: 1px solid rgb(166, 253, 84);
    color: rgb(255, 255, 255);
    border-radius: 5px;
    transition: 0.5s ease-in-out;
    font-size: 17px;
    text-align: center;
    font-family: 'Russo One', sans-serif;
  }


  .nameClaim2 {
    background-color: rgb(79, 158, 4);
    border: 1px solid rgb(181, 250, 117);
    color: rgb(255, 255, 255);
    border-radius: 5px;
    transition: 0.5s ease-in-out;
    font-size: 17px;
    text-align: center;
    font-family: 'Russo One', sans-serif;
    cursor: not-allowed;
  }

  .num2 {
    font-size: 20px;
    color: #e02712;
    margin-top: 15px;
    text-align: center;
  }

  .name2 {
    color: black;
    font-size: 18px;
    text-align: center;
    font-weight: 500;
  }

  .markBar img {
    width: 70px;
    height: 70px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 20%;
  }

  .markBarMain {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: 3%;
    margin-bottom: 5%;
  }

  .stakeMain {
    display: flex;
    flex-flow: column nowrap;
    overflow: hidden;
  }

  .stakeMain2 {
    border-right: 20px #f3ba2f solid;
    border-top: #f3ba2f 20px solid;
  }

  .sticky {
    width: 20%;

    bottom: 0;
    right: 90%;
    margin-top: -10%;
  }

  .items {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

  .board {
    margin-bottom: 5%;
    padding: 35px;
    border: #e02712 7px solid;
    background-color: #fffa00;
    border-radius: 50px;
    margin-left: 1%;
    margin-right: 1%;
    width: 65%;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  .boardMain {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 100%;

    margin-bottom: 7%;
  }

  .nameStake {
    width: 50%;
  }

  .carrots {
    width: 90px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .boardPics {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    margin-bottom: 8%;
  }

  .approveBtns {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    margin-top: 2%;
  }

  .approveBtns1 {
    border: 4px solid black;
    box-shadow: 0 0 5px gold;
    color: rgb(255, 255, 255);
    padding: 15px;
    border-radius: 20px;
    font-size: 23px;
    font-family: 'Russo One', sans-serif;
    width: 400px;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    background-color: #0a74ca;
  }

  .approveBtns1Dis {
    border: 4px solid black;
    box-shadow: 0 0 5px gold;
    color: rgb(255, 255, 255);
    padding: 15px;
    border-radius: 20px;
    font-size: 23px;
    font-family: 'Russo One', sans-serif;
    width: 400px;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    background-color: #0a74ca;
    cursor: no-drop;
  }

  .approveBtns1:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }

  .approveBtns2:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }

  .approveBtns3:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }

  .approveBtns3 {
    border: 4px solid black;
    box-shadow: 0 0 5px gold;
    color: rgb(255, 255, 255);
    padding: 15px;
    border-radius: 20px;
    font-size: 23px;
    font-family: 'Russo One', sans-serif;
    width: 400px;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    background-color: #f51761;
  }

  .approveBtns3Dis {
    border: 4px solid black;
    box-shadow: 0 0 5px gold;
    color: rgb(255, 255, 255);
    padding: 15px;
    border-radius: 20px;
    font-size: 23px;
    font-family: 'Russo One', sans-serif;
    width: 400px;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    background-color: #f51761;
    cursor: no-drop;
  }

  .approveBtns2 {
    background: none;
    color: white;
    padding: 20px;
    border-radius: 10px;
    font-size: 23px;
    border: 4px #70512b solid;

    width: 200px;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
  }


  .wallet3 {

    background-color: black;
    border: solid 3px;
    color: rgb(255, 255, 255);
    padding: 15px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    margin-left: auto;
    margin-right: auto;
    width: 270px;
    transition: -webkit-transform .5s;
    transition: transform .5s;
    transition: transform .5s, -webkit-transform .5s;
    z-index: 1;
    margin-bottom: 10%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    -webkit-animation: changeBorderColor2 5s infinite alternate;
            animation: changeBorderColor2 5s infinite alternate;

  }

  .lookDown {
    width: 20px;
    height: 20px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }


  .wallet3:hover {
    cursor: pointer;
  }

  .wallet3::after {
    transition: all 0.8s
  }

  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }

  .images_show {
    width: 90%;
  }

  .hide {
    display: none;
  }

  .stake {
    display: block;
    background-color: red;
    color: white;
    border-radius: 10px;
    border: none;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }

  .stake2 {
    display: block;
    background-color: red;
    color: white;
    border-radius: 10px;
    border: none;
    width: 85px;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-top: 10px;
    text-align: center;
  }

  .stake3 {
    display: block;
    background-color: red;
    color: white;
    border-radius: 10px;
    border: none;
    width: -webkit-max-content;
    width: max-content;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-bottom: 10px;
    text-align: center;
  }

  

  .tokenIds {
    display: flex;
    justify-content: center;
    color: white;
    flex-flow: row wrap;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    height: -webkit-max-content;
    height: max-content;
    margin-bottom: 10%;
  }

  .tokenIds2 {
    display: flex;
    justify-content: center;
    color: white;
    flex-flow: row wrap;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20%;
  }

  .tS {
    color: white;
    text-align: center;
    margin-top: 2%;
    font-size: 40px;
    font-family: 'Passion One', sans-serif;
  }

  .unstakeImg {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;

  }

  .imagesAndBtn {
    justify-content: center;
  }

  .flip-card {
    background-color: transparent;
    width: 150px;
    -webkit-perspective: 1000px;
            perspective: 1000px;
    background-color: #e0706c;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;

    margin-top: 10%;
  }

  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: -webkit-transform 0.6s;
    transition: transform 0.6s;
    transition: transform 0.6s, -webkit-transform 0.6s;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  .flip-card:hover .flip-card-inner {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }

  .flip-card-front,
  .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .btnCenter {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .flip-card-front {
    /* background-color: #bbb;*/
    color: black;
  }

  .flip-card-back {
    /*background-color: #2980b9;*/
    color: white;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }

  .loginFormNote {
    height: 90%;
    width: 90%;
    background-color: rgba(255, 255, 255, 0.13);
    position: absolute;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    border-radius: 10px;
    -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
    border: 2px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
    padding: 50px 35px;
  }

  .formMain {
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.856);
    position: fixed;
    width: 80%;
    height: 80vh;
    top: 50%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow-x: hidden;
    border-radius: 10px;
    z-index: 100000;
    border: white 3px solid;

  }

  .formMain1 {
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 100000;

    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    background-color: rgba(0, 0, 0, 0.857);
  }

  .ownNFT {
    margin-top: 13%;
    margin-bottom: 5.5%;
  }


  .elementName {
    font-weight: 500;
    color: red;
    font-size: 20px;
    text-align: center;
  }

  .elementName2 {
    font-weight: 500;
    color: rgb(255, 255, 255);
    font-size: 16px;
    margin-top: 5px;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .elementName3 {
    font-weight: 500;
    color: red;
    font-size: 20px;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .medalDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .closeNote {
    position: absolute;
    right: 0;
    top: 1%;
    color: rgba(255, 255, 255, 0.836);
    font-size: 30px;

    background-color: rgba(172, 67, 67, 0);
    width: 100px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    cursor: pointer;
    border: none;
  }

  .closeNote:hover,
  .closeNote:focus {
    color: rgb(71, 70, 70);
  }

  .rewards {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;

  }

  .discordB {
    display: none;
  }

  .medal {
    width: 35px;
    height: 35px;
  }

  .stakebtn:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }

  .stakebtn2:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }

  .stakebtnDiv {

    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .nftImg {
    width: 110px;
    margin-top: 20%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 2%;
  }

  .nftImgLogo {
    width: 110px;
    margin-top: 20%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    
    margin-bottom: 2%;
  }

  .nftImg2 {
    width: 110px;
    margin-top: 20%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .stakeT {
    color: #000000;
    font-family: 'Passion One', sans-serif;
    font-size: 45px;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1919px) {

  .stakeMainDiv {
    background-color: black;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
    background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%), url(/static/media/bg.16cea970.jpg) no-repeat center center fixed;
    background-size: cover;
  }

  .btns {

    width: 150px;
    background-color: #facc15;
    color: rgb(0, 0, 0);
    padding: 5px;
    font-weight: 500;
    margin-bottom: 5%;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;

  }

  .btnsDis {
    width: 150px;
    background-color: #77946c;
    border: none;
    color: rgb(255, 255, 255);
    padding: 5px;
    font-weight: 500;
    margin-bottom: 5%;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    cursor: no-drop;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  }

  .btns2 {
    width: 150px;
    color: rgb(0, 0, 0);
    padding: 5px;
    font-weight: 500;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    background-color: rgb(238, 62, 62);
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    border: none;
  }

  .btns2Dis {
    width: 150px;
    background-color: rgb(196, 134, 134);
    cursor: no-drop;
    color: rgb(255, 255, 255);
    padding: 5px;
    font-weight: 500;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  }

  .btns:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }

  .btns2:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }

  .btnsMain {
    display: flex;
    flex-flow: column nowrap;
    margin-top: auto;
    margin-bottom: auto;
    display: none;
  }

  .btnsMainBig {
    display: flex;
    flex-flow: column nowrap;
    margin-top: auto;
    margin-bottom: auto;
  }

  .markBar {
    margin-top: 2%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border-radius: 16px;
    background-color: black;
    padding: 20px;
    -webkit-animation: changeBorderColor 5s infinite alternate;
            animation: changeBorderColor 5s infinite alternate; /* Change 5s to your desired duration */
  
    /* Initial border style */
    border: 5px dashed #ee5849;
  }


  .markBarCon {
    background-color: #000000;
    border-radius: 20px;
    border: 1px solid white;
    display: flex;
    flex-flow: row nowrap;
    width: 20%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding: 10px;
    
  }

  .markBar2 {
    flex-flow: column nowrap;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: -10%;
  }

  .num {
    font-size: 15px;
    color: #ffffff;

    text-align: center;
  }

  .name {
    color: #ffffff;
    font-size: 17px;
    text-align: center;
    font-family: 'Russo One', sans-serif;
  }

  .nameClaim {
    background-color: rgb(87, 163, 15);
    border: 1px solid rgb(166, 253, 84);
    color: rgb(255, 255, 255);
    border-radius: 5px;
    transition: 0.5s ease-in-out;
    font-size: 17px;
    text-align: center;
    font-family: 'Russo One', sans-serif;
  }

  .nameClaim2 {
    background-color: rgb(79, 158, 4);
    border: 1px solid rgb(181, 250, 117);
    color: rgb(255, 255, 255);
    border-radius: 5px;
    transition: 0.5s ease-in-out;
    font-size: 17px;
    text-align: center;
    font-family: 'Russo One', sans-serif;
    cursor: not-allowed;
  }

  .num2 {
    font-size: 20px;
    color: #e02712;
    margin-top: 15px;
    text-align: center;
  }

  .name2 {
    color: black;
    font-size: 18px;
    text-align: center;
    font-weight: 500;
  }

  .markBar img {
    width: 70px;
    height: 70px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 20%;
  }

  .markBarMain {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: 3%;
    margin-bottom: 5%;
  }

  .stakeMain {
    display: flex;
    flex-flow: column nowrap;
    overflow: hidden;
  }

  .stakeMain2 {
    border-right: 20px #f3ba2f solid;
    border-top: #f3ba2f 20px solid;
  }

  .sticky {
    width: 20%;

    bottom: 0;
    right: 90%;
    margin-top: -10%;
  }

  .items {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

  .board {
    margin-bottom: 5%;
    padding: 35px;
    border: #e02712 7px solid;
    background-color: #fffa00;
    border-radius: 50px;
    margin-left: 1%;
    margin-right: 1%;
    width: 42%;
    
  }

  .boardMain {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    width: 85%;

  }

  .nameStake {
    width: 50%;
  }

  .carrots {
    width: 90px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .boardPics {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    margin-bottom: 8%;
  }

  .approveBtns {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    margin-top: 2%;
  }

  .approveBtns1 {
    border: 4px solid black;
    box-shadow: 0 0 5px gold;
    color: rgb(255, 255, 255);
    padding: 15px;
    border-radius: 20px;
    font-size: 23px;
    font-family: 'Russo One', sans-serif;
    width: 400px;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    background-color: #0a74ca;
  }

  .approveBtns1Dis {
    border: 4px solid black;
    box-shadow: 0 0 5px gold;
    color: rgb(255, 255, 255);
    padding: 15px;
    border-radius: 20px;
    font-size: 23px;
    font-family: 'Russo One', sans-serif;
    width: 400px;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    background-color: #0a74ca;
    cursor: no-drop;
  }

  .approveBtns1:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }

  .approveBtns2:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }

  .approveBtns3:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }

  .approveBtns3 {
    border: 4px solid black;
    box-shadow: 0 0 5px gold;
    color: rgb(255, 255, 255);
    padding: 15px;
    border-radius: 20px;
    font-size: 23px;
    font-family: 'Russo One', sans-serif;
    width: 400px;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    background-color: #f51761;
  }

  .approveBtns3Dis {
    border: 4px solid black;
    box-shadow: 0 0 5px gold;
    color: rgb(255, 255, 255);
    padding: 15px;
    border-radius: 20px;
    font-size: 23px;
    font-family: 'Russo One', sans-serif;
    width: 400px;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    background-color: #f51761;
    cursor: no-drop;
  }

  .approveBtns2 {
    background: none;
    color: white;
    padding: 20px;
    border-radius: 10px;
    font-size: 23px;
    border: 4px #70512b solid;

    width: 200px;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
  }


  .wallet3 {

    background-color: black;
    border: solid 3px;
    color: rgb(255, 255, 255);
    padding: 15px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    margin-left: auto;
    margin-right: auto;
    width: 270px;
    transition: -webkit-transform .5s;
    transition: transform .5s;
    transition: transform .5s, -webkit-transform .5s;
    z-index: 1;
    margin-bottom: 10%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    -webkit-animation: changeBorderColor2 5s infinite alternate;
            animation: changeBorderColor2 5s infinite alternate;
  }

  .lookDown {
    width: 20px;
    height: 20px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }


  .wallet3:hover {
    cursor: pointer;
  }

  .wallet3::after {
    transition: all 0.8s
  }

  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }

  .images_show {
    width: 90%;
  }

  .hide {
    display: none;
  }

  .stake {
    display: block;
    background-color: red;
    color: white;
    border-radius: 10px;
    border: none;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }

  .stake2 {
    display: block;
    background-color: red;
    color: white;
    border-radius: 10px;
    border: none;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-top: 10px;
    text-align: center;
  }

  .stake3 {
    display: block;
    background-color: red;
    color: white;
    border-radius: 10px;
    border: none;
    width: -webkit-max-content;
    width: max-content;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-bottom: 10px;
    text-align: center;
  }

  .tokenIds {
    display: flex;
    justify-content: center;
    color: white;
    flex-flow: row wrap;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    height: -webkit-max-content;
    height: max-content;
    margin-bottom: 10%;
  }

  .tokenIds2 {
    display: flex;
    justify-content: center;
    color: white;
    flex-flow: row wrap;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15%;
  }

  .tS {
    color: white;
    text-align: center;
    margin-top: 2%;
    font-size: 40px;
    font-family: 'Passion One', sans-serif;
  }

  .unstakeImg {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;

  }

  .imagesAndBtn {
    justify-content: center;
  }

  .flip-card {
    background-color: transparent;
    width: 150px;
    -webkit-perspective: 1000px;
            perspective: 1000px;
    background-color: #e0706c;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;

    margin-top: 10%;
  }

  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: -webkit-transform 0.6s;
    transition: transform 0.6s;
    transition: transform 0.6s, -webkit-transform 0.6s;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  .flip-card:hover .flip-card-inner {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }

  .flip-card-front,
  .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .btnCenter {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .flip-card-front {
    /* background-color: #bbb;*/
    color: black;
  }

  .flip-card-back {
    /*background-color: #2980b9;*/
    color: white;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }

  .loginFormNote {
    height: 90%;
    width: 90%;
    background-color: rgba(255, 255, 255, 0.13);
    position: absolute;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    border-radius: 10px;
    -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
    border: 2px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
    padding: 50px 35px;
  }


  .formMain {
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.856);
    position: fixed;
    width: 80%;
    height: 80vh;
    top: 50%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow-x: hidden;
    border-radius: 10px;
    z-index: 100000;
    border: white 3px solid;
  }


  .formMain2 {
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.856);
    position: fixed;
    width: 50%;
    height: 80vh;
    top: 50%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow-x: hidden;
    border-radius: 10px;
    z-index: 100000;
    border: white 3px solid;
    padding: 10px;
  }

  .formMain1 {
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 100000;
    background-color: rgba(0, 0, 0, 0.857);

  }

  .formMain1-1 {
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 100000;
    background-color: #00000062;
  }

  .ownNFT {
    margin-top: 8%;
    margin-bottom: 5.5%;
  }

  .elementName {
    font-weight: 500;
    color: red;
    font-size: 20px;
    text-align: center;
  }

  .elementName2 {
    font-weight: 500;
    color: rgb(255, 255, 255);
    font-size: 16px;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
    margin-top: 5px;
  }

  .claimIcon {
    width: 22px;
    height: 22px;
    margin-top: -5px;
  }

  .elementName3 {
    font-weight: 500;
    color: red;
    font-size: 20px;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .medalDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .closeNote {
    position: absolute;
    right: 0;
    top: 1%;
    color: rgba(255, 255, 255, 0.836);
    font-size: 30px;

    background-color: rgba(172, 67, 67, 0);
    width: 100px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    cursor: pointer;
    border: none;
  }

  .closeNote:hover,
  .closeNote:focus {
    color: rgb(71, 70, 70);
  }

  .rewards {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;

  }

  .stakebtn:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }

  .stakebtn2:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }

  .stakebtnDiv {

    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .nftImg {
    width: 110px;
    margin-top: 20%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 2%;
  }

  .nftImgLogo {
    width: 110px;
    margin-top: 20%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    
    margin-bottom: 2%;
  }

  .nftImg2 {
    width: 110px;
    margin-top: 20%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .stakeT {
    color: #000000;
    font-family: 'Passion One', sans-serif;
    font-size: 45px;
  }
}

@media screen and (min-width: 1920px) {

  .stakeMainDiv {
    background-color: black;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
    background: url(/static/media/bg.16cea970.jpg) no-repeat center center fixed;
    background-size: cover;
  }

  .btns {

    width: 150px;
    background-color: #facc15;
    color: rgb(0, 0, 0);
    padding: 5px;
    font-weight: 500;
    margin-bottom: 5%;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;

  }

  .btnsDis {
    width: 150px;
    background-color: #77946c;
    border: none;
    color: rgb(255, 255, 255);
    padding: 5px;
    font-weight: 500;
    margin-bottom: 5%;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    cursor: no-drop;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  }

  .btns2 {
    width: 150px;
    color: rgb(0, 0, 0);
    padding: 5px;
    font-weight: 500;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    background-color: rgb(238, 62, 62);
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    border: none;
  }

  .btns2Dis {
    width: 150px;
    background-color: rgb(196, 134, 134);
    cursor: no-drop;
    color: rgb(255, 255, 255);
    padding: 5px;
    font-weight: 500;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  }

  .btns:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }

  .btns2:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }

  .btnsMain {
    display: flex;
    flex-flow: column nowrap;
    margin-top: auto;
    margin-bottom: auto;
    display: none;
  }

  .btnsMainBig {
    display: flex;
    flex-flow: column nowrap;
    margin-top: auto;
    margin-bottom: auto;
  }

  .markBar {
    margin-top: 2%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border-radius: 22px;
    border: 8px dashed #ee5849;
    background-color: black;
    padding: 30px;
    -webkit-animation: changeBorderColor 5s infinite alternate;
            animation: changeBorderColor 5s infinite alternate;
  }

  .markBarCon {
    background-color: #000000;
    border-radius: 30px;
    border: 1px solid white;
    display: flex;
    flex-flow: row nowrap;
    width: 20%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding: 15px;
  }

  .markBar2 {
    flex-flow: column nowrap;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: -10%;
  }

  .num {
    font-size: 20px;
    color: #ffffff;

    text-align: center;
  }

  .name {
    color: #ffffff;
    font-size: 22px;
    text-align: center;
    font-family: 'Russo One', sans-serif;
  }

  .nameClaim {
    background-color: rgb(87, 163, 15);
    border: 2px solid rgb(166, 253, 84);
    color: rgb(255, 255, 255);
    border-radius: 5px;
    transition: 0.5s ease-in-out;
    font-size: 22px;
    text-align: center;
    font-family: 'Russo One', sans-serif;
  }


  .nameClaim2 {
    background-color: rgb(79, 158, 4);
    border: 1px solid rgb(181, 250, 117);
    color: rgb(255, 255, 255);
    border-radius: 5px;
    transition: 0.5s ease-in-out;
    font-size: 22px;
    text-align: center;
    font-family: 'Russo One', sans-serif;
    cursor: not-allowed;
  }

  .num2 {
    font-size: 30px;
    color: #e02712;
    margin-top: 15px;
    text-align: center;
  }

  .name2 {
    color: black;
    font-size: 28px;
    text-align: center;
    font-weight: 500;
  }

  .markBar img {
    width: 95px;
    height: 95px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 20%;
  }

  .markBarMain {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: 3%;
    margin-bottom: 5%;
  }

  .stakeMain {
    display: flex;
    flex-flow: column nowrap;
    overflow: hidden;
  }

  .stakeMain2 {
    border-right: 20px #f3ba2f solid;
    border-top: #f3ba2f 20px solid;
  }

  .sticky {
    width: 20%;

    bottom: 0;
    right: 90%;
    margin-top: -10%;
  }

  .items {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

  .board {
    margin-bottom: 5%;
    padding: 45px;
    border: #e02712 9px solid;
    background-color: #fffa00;
    border-radius: 60px;
    margin-left: 1%;
    margin-right: 1%;
    width: 42%;
  }

  .boardMain {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    width: 85%;

  }

  .nameStake {
    width: 50%;
  }

  .carrots {
    width: 90px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .boardPics {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    margin-bottom: 8%;
  }

  .approveBtns {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    margin-top: 2%;
  }

  .approveBtns1 {
    border: 6px solid black;
    color: white;
    padding: 18px;
    font-size: 35px;
    font-family: 'Russo One', sans-serif;
    width: 500px;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    background-color: #0a74ca;
    border-radius: 30px;
  }

  .approveBtns1Dis {
    border: 6px solid black;
    color: white;
    padding: 18px;
    font-size: 35px;
    font-family: 'Russo One', sans-serif;
    width: 500px;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    background-color: #0a74ca;
    border-radius: 30px;
    cursor: no-drop;
  }

  .approveBtns1:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }

  .approveBtns2:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }

  .approveBtns3:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }

  .approveBtns3 {
    border: 6px solid black;
    color: white;
    padding: 18px;
    font-size: 35px;
    font-family: 'Russo One', sans-serif;
    width: 500px;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    background-color: #f51761;
    border-radius: 30px;
  }

  .approveBtns3Dis {
    border: 6px solid black;
    color: white;
    padding: 18px;
    font-size: 35px;
    font-family: 'Russo One', sans-serif;
    width: 500px;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    background-color: #f51761;
    border-radius: 30px;
    cursor: no-drop;
  }

  .approveBtns2 {
    background: none;
    color: white;
    padding: 20px;
    border-radius: 10px;
    font-size: 23px;
    border: 4px #70512b solid;

    width: 200px;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
  }


  .wallet3 {

    background-color: black;
    border: solid 3px;
    color: rgb(255, 255, 255);
    padding: 15px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 30px;
    margin-left: auto;
    margin-right: auto;
    width: 450px;
    transition: -webkit-transform .5s;
    transition: transform .5s;
    transition: transform .5s, -webkit-transform .5s;
    z-index: 1;
    margin-bottom: 10%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    -webkit-animation: changeBorderColor2 5s infinite alternate;
            animation: changeBorderColor2 5s infinite alternate;

  }

  .lookDown {
    width: 30px;
    height: 30px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }


  .wallet3:hover {
    cursor: pointer;
  }

  .wallet3::after {
    transition: all 0.8s
  }

  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }

  .images_show {
    width: 90%;
  }

  .hide {
    display: none;
  }

  .stake {
    display: block;
    background-color: red;
    color: white;
    border-radius: 10px;
    border: none;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }

  .stake2 {
    display: block;
    background-color: red;
    color: white;
    border-radius: 10px;
    border: none;
    width: -webkit-max-content;
    width: max-content;
    padding: 5px;
    padding-left: 25px;
    padding-right: 25px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-top: 10px;
    text-align: center;
    font-size: 25px;

  }

  .stake3 {
    display: block;
    background-color: red;
    color: white;
    border-radius: 10px;
    border: none;
    width: -webkit-max-content;
    width: max-content;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-bottom: 10px;
    text-align: center;
    font-size: 25px;
  }

  .tokenIds {
    display: flex;
    justify-content: center;
    color: white;
    flex-flow: row wrap;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    height: -webkit-max-content;
    height: max-content;
    margin-bottom: 10%;
  }

  .tokenIds2 {
    display: flex;
    justify-content: center;
    color: white;
    flex-flow: row wrap;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15%;
  }

  .tS {
    color: white;
    text-align: center;
    margin-top: 2%;
    font-size: 40px;
    font-family: 'Passion One', sans-serif;
  }

  .unstakeImg {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;

  }

  .imagesAndBtn {
    justify-content: center;
  }

  .flip-card {
    background-color: transparent;
    width: 150px;
    -webkit-perspective: 1000px;
            perspective: 1000px;
    background-color: #e0706c;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;

    margin-top: 10%;
  }

  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: -webkit-transform 0.6s;
    transition: transform 0.6s;
    transition: transform 0.6s, -webkit-transform 0.6s;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  .flip-card:hover .flip-card-inner {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }

  .flip-card-front,
  .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .btnCenter {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .flip-card-front {
    /* background-color: #bbb;*/
    color: black;
  }

  .flip-card-back {
    /*background-color: #2980b9;*/
    color: white;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }

  .loginFormNote {
    height: 90%;
    width: 90%;
    background-color: rgba(255, 255, 255, 0.13);
    position: absolute;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    border-radius: 10px;
    -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
    border: 2px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
    padding: 50px 35px;
  }

  .formMain {
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.856);
    position: fixed;
    width: 80%;
    height: 80vh;
    top: 50%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow-x: hidden;
    border-radius: 10px;
    z-index: 100000;
    border: white 3px solid;
  }

  .formMain1 {
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 100000;

    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    background-color: rgba(0, 0, 0, 0.857);
  }

  .ownNFT {
    margin-bottom: 12%;
  }


  .elementName {
    font-weight: 500;
    color: red;
    font-size: 20px;
    text-align: center;
  }

  .elementName2 {
    font-weight: 500;
    color: rgb(255, 255, 255);
    font-size: 20px;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
    margin-top: 5px;
  }

  .claimIcon {
    width: 25px;
    height: 25px;
    margin-top: -5px;
  }

  .medalDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .closeNote {
    position: absolute;
    right: 0;
    top: 1%;
    color: rgba(255, 255, 255, 0.836);
    font-size: 30px;

    background-color: rgba(172, 67, 67, 0);
    width: 100px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    cursor: pointer;
    border: none;
  }

  .closeNote:hover,
  .closeNote:focus {
    color: rgb(71, 70, 70);
  }

  .rewards {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;

  }

  .discordB {
    display: none;
  }

  .medal {
    width: 35px;
    height: 35px;
  }

  .stakebtn:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }

  .stakebtn2:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }

  .stakebtnDiv {

    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .nftImg {
    width: 155px;
    margin-top: 20%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 2%;
  }

  .nftImgLogo {
    width: 155px;
    margin-top: 20%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    
    margin-bottom: 2%;
  }

  .nftImg2 {
    width: 155px;
    margin-top: 20%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .stakeT {
    color: #000000;
    font-family: 'Passion One', sans-serif;
    color: black;
    font-size: 60px;
  }
}

@media screen and (min-width: 1921px) and (max-width: 3839px) {

  
  .stakeMainDiv {
    background-color: black;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
    background: url(/static/media/bg.16cea970.jpg) no-repeat center center fixed;
    background-size: cover;
  }

  .btns {

    width: 150px;
    background-color: #facc15;
    color: rgb(0, 0, 0);
    padding: 5px;
    font-weight: 500;
    margin-bottom: 5%;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;

  }

  .btnsDis {
    width: 150px;
    background-color: #77946c;
    border: none;
    color: rgb(255, 255, 255);
    padding: 5px;
    font-weight: 500;
    margin-bottom: 5%;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    cursor: no-drop;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  }

  .btns2 {
    width: 150px;
    color: rgb(0, 0, 0);
    padding: 5px;
    font-weight: 500;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    background-color: rgb(238, 62, 62);
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    border: none;
  }

  .btns2Dis {
    width: 150px;
    background-color: rgb(196, 134, 134);
    cursor: no-drop;
    color: rgb(255, 255, 255);
    padding: 5px;
    font-weight: 500;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  }

  .btns:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }

  .btns2:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }

  .btnsMain {
    display: flex;
    flex-flow: column nowrap;
    margin-top: auto;
    margin-bottom: auto;
    display: none;
  }

  .btnsMainBig {
    display: flex;
    flex-flow: column nowrap;
    margin-top: auto;
    margin-bottom: auto;
  }

  .markBar {
    margin-top: 2%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border-radius: 40px;
    border: 10px dashed #ee5849;
    background-color: black;
    padding: 45px;
    -webkit-animation: changeBorderColor 5s infinite alternate;
            animation: changeBorderColor 5s infinite alternate;
  }

  .markBarCon {
    background-color: #000000;
    border-radius: 40px;
    border: 1px solid white;
    display: flex;
    flex-flow: row nowrap;
    width: 20%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding: 20px;
  }

  .markBar2 {
    flex-flow: column nowrap;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: -10%;
  }

  .num {
    font-size: 27px;
    color: #ffffff;

    text-align: center;
  }

  .name {
    color: #ffffff;
    font-size: 30px;
    text-align: center;
    font-family: 'Russo One', sans-serif;
  }

  .nameClaim {
    background-color: rgb(87, 163, 15);
    border: 3px solid rgb(166, 253, 84);
    color: rgb(255, 255, 255);
    border-radius: 5px;
    transition: 0.5s ease-in-out;
    font-size: 30px;
    text-align: center;
    font-family: 'Russo One', sans-serif;
  }


  .nameClaim2 {
    background-color: rgb(79, 158, 4);
    border: 4px solid rgb(181, 250, 117);
    color: rgb(255, 255, 255);
    border-radius: 15px;
    padding: 5px;
    transition: 0.5s ease-in-out;
    font-size: 30px;
    text-align: center;
    font-family: 'Russo One', sans-serif;
    cursor: not-allowed;
  }

  .num2 {
    font-size: 40px;
    color: #e02712;
    margin-top: 15px;
    text-align: center;
  }

  .name2 {
    color: black;
    font-size: 38px;
    text-align: center;
    font-weight: 500;
  }

  .markBar img {
    width: 115px;
    height: 115px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 20%;
  }

  .markBarMain {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: 3%;
    margin-bottom: 5%;
  }

  .stakeMain {
    display: flex;
    flex-flow: column nowrap;
    overflow: hidden;
  }

  .stakeMain2 {
    border-right: 20px #f3ba2f solid;
    border-top: #f3ba2f 20px solid;
  }

  .sticky {
    width: 20%;

    bottom: 0;
    right: 90%;
    margin-top: -10%;
  }

  .items {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

  .board {
    margin-bottom: 5%;
    padding: 50px;
    border: #e02712 12px solid;
    background-color: #fffa00;
    border-radius: 80px;
    margin-left: 1%;
    margin-right: 1%;
    width: 42%;
  }

  .boardMain {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    width: 85%;

  }

  .nameStake {
    width: 50%;
  }

  .carrots {
    width: 90px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .boardPics {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    margin-bottom: 8%;
  }

  .approveBtns {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    margin-top: 2%;
  }

  .approveBtns1 {
    border: 8px solid black;
    color: white;
    padding: 25px;
    font-size: 45px;
    font-family: 'Russo One', sans-serif;
    width: 700px;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    background-color: #0a74ca;
    border-radius: 20px;
  }

  .approveBtns1Dis {
    border: 8px solid black;
    color: white;
    padding: 25px;
    font-size: 45px;
    font-family: 'Russo One', sans-serif;
    width: 700px;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    background-color: #0a74ca;
    border-radius: 20px;
    cursor: no-drop;
  }

  .approveBtns1:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }

  .approveBtns2:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }

  .approveBtns3:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }

  .approveBtns3 {
    border: 8px solid black;
    color: white;
    padding: 25px;
    font-size: 45px;
    font-family: 'Russo One', sans-serif;
    width: 700px;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    background-color: #f51761;
    border-radius: 20px;
  }

  .approveBtns3Dis {
    border: 8px solid black;
    color: white;
    padding: 25px;
    font-size: 45px;
    font-family: 'Russo One', sans-serif;
    width: 700px;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    background-color: #f51761;
    border-radius: 20px;
    cursor: no-drop;
  }

  .approveBtns2 {
    background: none;
    color: white;
    padding: 20px;
    border-radius: 10px;
    font-size: 23px;
    border: 4px #70512b solid;

    width: 200px;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
  }


  .wallet3 {

    background-color: black;
    border: solid 5px;
    color: rgb(255, 255, 255);
    padding: 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 40px;
    margin-left: auto;
    margin-right: auto;
    width: 500px;
    transition: -webkit-transform .5s;
    transition: transform .5s;
    transition: transform .5s, -webkit-transform .5s;
    z-index: 1;
    margin-bottom: 10%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    -webkit-animation: changeBorderColor2 5s infinite alternate;
            animation: changeBorderColor2 5s infinite alternate;

  }

  .lookDown {
    width: 30px;
    height: 30px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }


  .wallet3:hover {
    cursor: pointer;
  }

  .wallet3::after {
    transition: all 0.8s
  }

  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }

  .images_show {
    width: 90%;
  }

  .hide {
    display: none;
  }

  .stake {
    display: block;
    background-color: red;
    color: white;
    border-radius: 10px;
    border: none;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }

  .stake2 {
    display: block;
    background-color: red;
    color: white;
    border-radius: 10px;
    border: none;
    width: -webkit-max-content;
    width: max-content;
    padding: 7px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-top: 10px;
    text-align: center;
    font-size: 25px;

  }

  .stake3 {
    display: block;
    background-color: red;
    color: white;
    border-radius: 10px;
    border: none;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-bottom: 10px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 25px;

  }

  .tokenIds {
    display: flex;
    justify-content: center;
    color: white;
    flex-flow: row wrap;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    height: -webkit-max-content;
    height: max-content;
    margin-bottom: 10%;
  }

  .tokenIds2 {
    display: flex;
    justify-content: center;
    color: white;
    flex-flow: row wrap;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15%;
  }

  .tS {
    color: white;
    text-align: center;
    margin-top: 2%;
    font-size: 40px;
    font-family: 'Passion One', sans-serif;
  }

  .unstakeImg {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;

  }

  .imagesAndBtn {
    justify-content: center;
  }

  .flip-card {
    background-color: transparent;
    width: 150px;
    -webkit-perspective: 1000px;
            perspective: 1000px;
    background-color: #e0706c;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;

    margin-top: 10%;
  }

  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: -webkit-transform 0.6s;
    transition: transform 0.6s;
    transition: transform 0.6s, -webkit-transform 0.6s;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  .flip-card:hover .flip-card-inner {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }

  .flip-card-front,
  .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .btnCenter {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .flip-card-front {
    /* background-color: #bbb;*/
    color: black;
  }

  .flip-card-back {
    /*background-color: #2980b9;*/
    color: white;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }

  .loginFormNote {
    height: 90%;
    width: 90%;
    background-color: rgba(255, 255, 255, 0.13);
    position: absolute;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    border-radius: 10px;
    -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
    border: 2px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
    padding: 50px 35px;
  }

  .formMain {
    z-index: 10000000000000000;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.856);
    position: fixed;
    width: 80%;
    height: 80vh;
    top: 50%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow-x: hidden;
    border-radius: 10px;
    border: white 3px solid;

  }

  .formMain2 {
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.856);
    position: fixed;
    width: 50%;
    height: 80vh;
    top: 50%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow-x: hidden;
    border-radius: 10px;
    z-index: 100000;
    border: white 3px solid;
    padding: 10px;
  }

  .formMain1 {
    z-index: 10000000000000000;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    background-color: rgba(0, 0, 0, 0.857);
  }

  .formMain1-1 {
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 100000;
    background-color: #00000062;
  }

  .ownNFT {
    margin-top: 8%;
    margin-bottom: 5.5%;
  }

  .elementName {
    font-weight: 500;
    color: red;
    font-size: 20px;
    text-align: center;
  }

  .elementName2 {
    font-weight: 500;
    color: rgb(255, 255, 255);
    font-size: 25px;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .claimIcon {
    width: 30px;
    height: 30px;
    margin-top: -5px;
  }

  .elementName3 {
    font-weight: 500;
    color: red;
    font-size: 20px;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .medalDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .closeNote {
    position: absolute;
    right: 0;
    top: 1%;
    color: rgba(255, 255, 255, 0.836);
    font-size: 30px;

    background-color: rgba(172, 67, 67, 0);
    width: 100px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    cursor: pointer;
    border: none;
  }

  .closeNote:hover,
  .closeNote:focus {
    color: rgb(71, 70, 70);
  }

  .rewards {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;

  }

  .discordB {
    display: none;
  }

  .medal {
    width: 35px;
    height: 35px;
  }

  .stakebtn:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }

  .stakebtn2:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }

  .stakebtnDiv {

    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .nftImg {
    width: 200px;
    margin-top: 20%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 2%;
  }

  .nftImgLogo {
    width: 200px;
    margin-top: 20%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    
    margin-bottom: 2%;
  }

  .nftImg2 {
    width: 200px;
    margin-top: 20%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .stakeT {
    color: #000000;
    font-family: 'Passion One', sans-serif;
    color: black;
    font-size: 90px;
  }
}

@media screen and (min-width: 3840px) {

  .stakeMainDiv {
    background-color: black;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
    background: url(/static/media/bg.16cea970.jpg) no-repeat center center fixed;
    background-size: cover;
  }

  .btns {

    width: 150px;
    background-color: #facc15;
    color: rgb(0, 0, 0);
    padding: 5px;
    font-weight: 500;
    margin-bottom: 5%;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;

  }

  .btnsDis {
    width: 150px;
    background-color: #77946c;
    border: none;
    color: rgb(255, 255, 255);
    padding: 5px;
    font-weight: 500;
    margin-bottom: 5%;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    cursor: no-drop;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  }

  .btns2 {
    width: 150px;
    color: rgb(0, 0, 0);
    padding: 5px;
    font-weight: 500;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    background-color: rgb(238, 62, 62);
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    border: none;
  }

  .btns2Dis {
    width: 150px;
    background-color: rgb(196, 134, 134);
    cursor: no-drop;
    color: rgb(255, 255, 255);
    padding: 5px;
    font-weight: 500;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  }

  .btns:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }

  .btns2:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }

  .btnsMain {
    display: flex;
    flex-flow: column nowrap;
    margin-top: auto;
    margin-bottom: auto;
    display: none;
  }

  .btnsMainBig {
    display: flex;
    flex-flow: column nowrap;
    margin-top: auto;
    margin-bottom: auto;
  }

  .markBar {
    margin-top: 2%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border-radius: 50px;
    border: 15px dashed #ee5849;
    background-color: black;
    padding: 55px;
    -webkit-animation: changeBorderColor 5s infinite alternate;
            animation: changeBorderColor 5s infinite alternate;
  }

  .markBarCon {
    background-color: #000000;
    border-radius: 60px;
    border: 1px solid white;
    display: flex;
    flex-flow: row nowrap;
    width: 20%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding: 40px;
  }

  .markBar2 {
    flex-flow: column nowrap;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: -10%;
  }

  .num {
    font-size: 40px;
    color: #ffffff;

    text-align: center;
  }

  .name {
    color: #ffffff;
    font-size: 45px;
    text-align: center;
    font-family: 'Russo One', sans-serif;
  }

  .nameClaim {
    background-color: rgb(87, 163, 15);
    border: 3px solid rgb(166, 253, 84);
    color: rgb(255, 255, 255);
    border-radius: 5px;
    transition: 0.5s ease-in-out;
    font-size: 45px;
    text-align: center;
    font-family: 'Russo One', sans-serif;
  }


  .nameClaim2 {
    background-color: rgb(79, 158, 4);
    border: 6px solid rgb(181, 250, 117);
    color: rgb(255, 255, 255);
    padding: 10px;
    border-radius: 20px;
    transition: 0.5s ease-in-out;
    font-size: 45px;
    text-align: center;
    font-family: 'Russo One', sans-serif;
    cursor: not-allowed;
  }

  .num2 {
    font-size: 57px;
    color: #e02712;
    margin-top: 15px;
    text-align: center;
  }

  .name2 {
    color: black;
    font-size: 55px;
    text-align: center;
    font-weight: 500;
  }

  .markBar img {
    width: 165px;
    height: 165px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 20%;
  }

  .markBarMain {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: 3%;
    margin-bottom: 5%;
  }

  .stakeMain {
    display: flex;
    flex-flow: column nowrap;
    overflow: hidden;
  }

  .stakeMain2 {
    border-right: 20px #f3ba2f solid;
    border-top: #f3ba2f 20px solid;
  }

  .sticky {
    width: 20%;

    bottom: 0;
    right: 90%;
    margin-top: -10%;
  }

  .items {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

  .board {
    margin-bottom: 5%;
    padding: 65px;
    border: #e02712 14px solid;
    background-color: #fffa00;
    
    margin-left: 1%;
    margin-right: 1%;
    width: 42%;
  }

  .boardMain {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    width: 85%;

  }

  .nameStake {
    width: 50%;
  }

  .carrots {
    width: 90px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .boardPics {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    margin-bottom: 8%;
  }

  .approveBtns {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    margin-top: 2%;
  }

  .approveBtns1 {
    border: 12px solid black;
    color: rgb(255, 255, 255);
    padding: 35px;
    font-size: 65px;
    font-family: 'Russo One', sans-serif;
    width: 1000px;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    background-color: #0a74ca;
  }

  .approveBtns1Dis {
    border: 12px solid black;
    color: rgb(255, 255, 255);
    padding: 35px;
    font-size: 65px;
    font-family: 'Russo One', sans-serif;
    width: 1000px;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    background-color: #0a74ca;
    cursor: no-drop;
  }

  .approveBtns1:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }

  .approveBtns2:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }

  .approveBtns3:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }

  .approveBtns3 {
    border: 12px solid black;
    color: rgb(255, 255, 255);
    padding: 35px;
    font-size: 65px;
    font-family: 'Russo One', sans-serif;
    width: 1000px;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    background-color: #f51761;
  }

  .approveBtns3Dis {
    cursor: no-drop;
    border: 12px solid black;
    color: rgb(255, 255, 255);
    padding: 35px;
    font-size: 65px;
    font-family: 'Russo One', sans-serif;
    width: 1000px;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    background-color: #f51761;
  }

  .approveBtns2 {
    background: none;
    color: white;
    padding: 20px;
    border-radius: 10px;
    font-size: 23px;
    border: 4px #70512b solid;

    width: 200px;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
  }


  .wallet3 {

    background-color: black;
    border: solid 7px;
    color: rgb(255, 255, 255);
    padding: 35px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 60px;
    margin-left: auto;
    margin-right: auto;
    width: 800px;
    transition: -webkit-transform .5s;
    transition: transform .5s;
    transition: transform .5s, -webkit-transform .5s;
    z-index: 1;
    margin-bottom: 10%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    -webkit-animation: changeBorderColor2 5s infinite alternate;
            animation: changeBorderColor2 5s infinite alternate;

  }

  .lookDown {
    width: 50px;
    height: 50px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }


  .wallet3:hover {
    cursor: pointer;
  }

  .wallet3::after {
    transition: all 0.8s
  }

  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }

  .images_show {
    width: 90%;
  }

  .hide {
    display: none;
  }

  .stake {
    display: block;
    background-color: red;
    color: white;
    border-radius: 10px;
    border: none;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }

  .stake2 {
    display: block;
    background-color: red;
    color: white;
    border-radius: 15px;
    border: none;
    padding: 10px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-top: 10px;
    text-align: center;
  }

  .stake3 {
    display: block;
    background-color: red;
    color: white;
    border-radius: 15px;
    border: none;
    width: -webkit-max-content;
    width: max-content;
    padding: 10px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-bottom: 10px;
    text-align: center;
  }

  .tokenIds {
    display: flex;
    justify-content: center;
    color: white;
    flex-flow: row wrap;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    height: -webkit-max-content;
    height: max-content;
    margin-bottom: 10%;
  }

  .tokenIds2 {
    display: flex;
    justify-content: center;
    color: white;
    flex-flow: row wrap;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15%;
  }

  .tS {
    color: white;
    text-align: center;
    margin-top: 2%;
    font-size: 40px;
    font-family: 'Passion One', sans-serif;
  }

  .unstakeImg {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;

  }

  .imagesAndBtn {
    justify-content: center;
  }

  .flip-card {
    background-color: transparent;
    width: 150px;
    -webkit-perspective: 1000px;
            perspective: 1000px;
    background-color: #e0706c;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;

    margin-top: 10%;
  }

  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: -webkit-transform 0.6s;
    transition: transform 0.6s;
    transition: transform 0.6s, -webkit-transform 0.6s;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  .flip-card:hover .flip-card-inner {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }

  .flip-card-front,
  .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .btnCenter {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .flip-card-front {
    /* background-color: #bbb;*/
    color: black;
  }

  .flip-card-back {
    /*background-color: #2980b9;*/
    color: white;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }

  .loginFormNote {
    height: 90%;
    width: 90%;
    background-color: rgba(255, 255, 255, 0.13);
    position: absolute;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    border-radius: 10px;
    -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
    border: 2px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
    padding: 50px 35px;
  }

  .formMain {
    z-index: 10000000000000000;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.856);
    position: fixed;
    width: 80%;
    height: 80vh;
    top: 50%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow-x: hidden;
    border-radius: 10px;
    border: white 3px solid;

  }

  .formMain2 {
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.856);
    position: fixed;
    width: 50%;
    height: 80vh;
    top: 50%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow-x: hidden;
    border-radius: 10px;
    z-index: 100000;
    border: white 3px solid;
    padding: 10px;
  }

  .formMain1 {
    z-index: 10000000000000000;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    background-color: rgba(0, 0, 0, 0.857);
  }

  .formMain1-1 {
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 100000;
    background-color: #00000062;
  }

  .ownNFT {
    margin-top: 8%;
    margin-bottom: 5.5%;
  }

  .elementName {
    font-weight: 500;
    color: red;
    font-size: 20px;
    text-align: center;
  }

  .elementName2 {
    font-weight: 500;
    color: rgb(255, 255, 255);
    font-size: 28px;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .claimIcon {
    width: 35px;
    height: 35px;
    margin-top: -5px;
  }

  .elementName3 {
    font-weight: 500;
    color: red;
    font-size: 20px;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .medalDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .closeNote {
    position: absolute;
    right: 0;
    top: 1%;
    color: rgba(255, 255, 255, 0.836);
    font-size: 30px;

    background-color: rgba(172, 67, 67, 0);
    width: 100px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    cursor: pointer;
    border: none;
  }

  .closeNote:hover,
  .closeNote:focus {
    color: rgb(71, 70, 70);
  }

  .rewards {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;

  }

  .discordB {
    display: none;
  }

  .medal {
    width: 35px;
    height: 35px;
  }

  .stakebtn:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }

  .stakebtn2:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }

  .stakebtnDiv {

    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .nftImg {
    width: 300px;
    margin-top: 20%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 2%;
  }

  .nftImgLogo {
    width: 300px;
    margin-top: 20%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 2%;
  }

  .nftImg2 {
    width: 300px;
    margin-top: 20%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .stakeT {
    color: #000000;
    font-family: 'Passion One', sans-serif;
    color: black;
    font-size: 120px;
  }
}
